import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { RegisterPageRoutingModule } from './../register/register-routing.module';

@Injectable({
  providedIn: 'root'
})

//////////////////////////////////////////////////////////////////////////
// Navigator is in charge of:
// 1. serving features according the license customer paid for
// 2. feature flag - launching new features to specific users for QA, then to all
//////////////////////////////////////////////////////////////////////////

export class NavigatorService {

  constructor(private router: Router) { }

  /*
  todo here:
  1. from org chosen and saved in cookie - take list of approved routes
  2. from all routing places call this method
  3. take the available routes to this org from the browser memory 
  4. check if this route is in list
  5. if yes - route to it
  6. if not - show message and send error to server
  */
  go(route_str, navigationExtras=null){
    if (navigationExtras==null) {
      this.router.navigate([route_str]);
    } else {
      this.router.navigate([route_str], navigationExtras);
    }
  }
}
