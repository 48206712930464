import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from '@angular/common/http';
// import {  Router} from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MockupService } from './mockup';
import { ConsService } from './cons.service';
import { StorageService } from './storage.service';
import { UtilzService } from 'src/app/services/utilz.service';
import { NavigatorService } from './navigator.service';
import { tap } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';


const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';

@Injectable({
  providedIn: 'root'
})
//////////////////////////////////////////////////////////////
export class AuthService {
  features_list = [];
  public user: Observable<any>;
  private userData = new BehaviorSubject(null);
  private jwtHelperService = new JwtHelperService();
  //////////////////////////////////////////////////////////////
  constructor(private storage: Storage, private httpClient: HttpClient, private plt: Platform,
    private apiService: ApiService, private consService: ConsService, private mockupService: MockupService,
    public router: Router,
    private storageService: StorageService, private navigator: NavigatorService, public utils: UtilzService) {
    this.loadStoredToken();
  }

  //////////////////////////////////////////////////////////////
  loadStoredToken() {
    let platformObs = from(this.plt.ready());

    this.user = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(TOKEN_KEY));
      }),
      map(token => {
        if (token) {
          let decoded = helper.decodeToken(token);
          this.userData.next(decoded);
          return true;
        } else {
          return null;
        }
      })
    );
  }
  //////////////////////////////////////////////////////////////
  login(credentials: { email: string, pwd: string }) {
    // : { success: boolean, msg: string, res: any} {
    let email = credentials.email;
    let password = credentials.pwd;

    let body = {
      "email": email,
      "password": password,
    }

    let url = this.apiService.getApiUrl(this.consService.LOGIN_ACTION, false);

    let token: string = '';

    try {
      var result = this.execute_login_req(url, body);
      return result;
    }
    catch (e) {
      this.utils.errorReporter("auth.service login() Exception", e);
    }
  }

  //////////////////////////////////////////

  execute_login_req(url: string, body: { email: string, password: string }) {
    return this.apiService.sendPostRequest(url, body).pipe(
      take(1),
      map(res => {
        // Extract the JWT, 
        return res;
      }),
      switchMap(token => {

        //TODO : fix this.storage mechanism, and remove localStorage one
        ///////////////////////////////
        // localStorage Mechanism
        ///////////////////////////////
        this.storageService.setValue(this.storageService.ACCESS_TOKEN_KEY, token['access'])
        this.storageService.setValue(this.storageService.REFRESH_TOKEN_KEY, token['refresh']);
        ///////////////////////////////
        // this.storage Mechanism
        ///////////////////////////////
        let decoded = helper.decodeToken(token['access']);
        this.userData.next(decoded);
        let storageObs = from(this.storage.set(TOKEN_KEY, token));
        return storageObs;
      })
    );
  }
  //////////////////////////////////////////////////////////////
  presentAlert(header: string, subHeader: string, message: string, button_title: string) {
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'login_alert';
    alert.header = header;
    alert.subHeader = subHeader;
    alert.message = message;
    alert.buttons = [button_title];

    document.body.appendChild(alert);
    return alert.present();
  }

  ////////////////////////////////////////////
  isTokenExpired(): boolean {
    const token = this.storageService.getValue(this.storageService.ACCESS_TOKEN_KEY);
    const token_is_expired = this.jwtHelperService.isTokenExpired(token);
    if (token_is_expired) { //in case timeout
      //promise: log in
      const access_token = this.setNewAccessToken();
      this.storageService.setValue(this.storageService.ACCESS_TOKEN_KEY, access_token['access']); // or raccess_token?

    }
    return token_is_expired;
  }

  isAuthenticated() {
    const token = this.storageService.getValue(this.storageService.ACCESS_TOKEN_KEY);
    if (token) {
      return true
    } else {
      return false
    }
  }

  ///////////////////////////////////////////////  
  // Store a new access token
  storeAccessToken(accessToken) {
    // this.currentAccessToken = accessToken;
    // return from(Storage.set({ key: ACCESS_TOKEN_KEY, value: accessToken }));
  }
  ///////////////////////////////////////////////  
  getUser() {
    return this.userData.getValue();
  }
  //////////////////////////////////////////////////////////////
  toLogin() {
    this.navigator.go('/');
  }

  //////////////////////////////////////////////////////////////
  logout(errorMessage?) {
    this.utils.resetStorageInLogout();
    this.features_list = []
    this.storage.remove(TOKEN_KEY).then(() => {
      if (errorMessage) {
        this.storageService.setValue(this.storageService.APP_ERROR_MSG, errorMessage)
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login']);
      }
      this.userData.next(null);
    });
  }
  //////////////////////////////////////////////////////////////
  setNewAccessToken() {

    let refresh_url = '/api/auth/jwt/refresh/';
    return this.httpClient.post(refresh_url, {
      refresh: this.storageService.getValue(this.storageService.REFRESH_TOKEN_KEY)
    }).pipe(tap((token) => {
      const new_access_token = token['access'];
      this.storageService.setValue(this.storageService.ACCESS_TOKEN_KEY, token['access']);
      if (new_access_token) {
        return new_access_token;
      } else {
        return false
      }
    }));
  }

  setMenu() {
    if (this.isAuthenticated()) {
      if (!this.features_list || this.features_list.length == 0) {
        this.apiService.getMenu().subscribe((res: any) => {
          this.features_list = res;
          this.features_list = this.removeNull(this.features_list)
        })
      }
    }
  }

  removeNull(array) {
    let new_array = [];
    for (let i in array) {
      if (array[i].label.trim().length > 0) {
        new_array.push(array[i]);
      } 
    }
    return new_array;
  };
}
