import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { local } from 'd3';
import { ConsService } from './cons.service';
import { UtilzService } from './utilz.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userMenuCalledOnce = false
  constructor(public auth: AuthService, public translate: TranslateService, private consService: ConsService, 
    private utilz: UtilzService, private storageService: StorageService, private api: ApiService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {


        if (!this.userMenuCalledOnce) {
          if (this.auth.isAuthenticated() && this.api.getOrgId() && this.auth.features_list.length == 0) {
            this.userMenuCalledOnce = true
            this.auth.setMenu()
          }
        }
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success) {
            if (this.auth.isAuthenticated() && this.api.getOrgId() && this.auth.features_list.length == 0) {
              this.auth.setMenu()
            }
          }
          // this.toasterService.success(evt.body.success.message, evt.body.success.title, { positionClass: 'toast-bottom-center' });
        }
      }),
      catchError((err: any) => {

        if (err instanceof HttpErrorResponse) {
          try {
            if (err.status == 401) {
              console.log("Auth Error: 401");
              if (window.location.pathname.includes('login')) {
                var error_msg = this.translate.instant('EMAIL OR PASSWORD ARE NOT CORRECT');
                // console.log('# Error: ', error_msg);
                this.utilz.presentAlert(this.translate.instant('LOGIN ISSUE'), "", error_msg, this.translate.instant('OK'));
              }

              let reloadCounter
              if (reloadCounter <= 3) {
                if (!this.storageService.getValue(this.storageService.REFRESH_COUNTER)) {
                  this.storageService.setValue(this.storageService.REFRESH_COUNTER, "1")
                  reloadCounter = 1
                } else {
                  reloadCounter = parseInt(this.storageService.getValue(this.storageService.REFRESH_COUNTER))
                  reloadCounter = reloadCounter + 1
                  this.storageService.setValue(this.storageService.REFRESH_COUNTER, reloadCounter.toString())
                }
                this.auth.setNewAccessToken().subscribe(res => {
                  // TODO - solve the refresh token by uncommenting this line or implement another thing.
                  if (res) {
                    // console.log("Auth Res: ", res);
                    //window.location.reload();
                  } else {
                    console.log("!!! Auth Error: logout 2 - NO TOKEN ISSUE")
                    this.auth.logout(this.translate.instant(this.translate.instant('PLEASE LOGIN AGAIN')))
                    return;
                  }
                }, err => {
                  console.log("!!! Auth Error: error 1 - ACCESS TOKEN ISSUE")
                  this.auth.logout(this.translate.instant('PLEASE LOGIN AGAIN'));
                })
              } else {
                console.log("!!! Auth Error: logout 1. reload more than 3 times.")
                this.auth.logout(this.translate.instant('PLEASE LOGIN AGAIN'))
              }

              console.log("!!! Auth Error: error 2 - YOUR SESSION WAS ENDED")
              this.auth.logout(this.translate.instant('SESSION WAS ENDED SO PLEASE LOGIN AGAIN'))
            }
            if (err.status == 0) {
              console.log("!!! Auth Error: error 3")
              this.auth.logout(this.translate.instant('PLEASE CONNECT TO INTERNET'))
            }

            console.log("!!! Auth Error: error 4")
            // this.auth.logout(this.translate.instant("NOT SURE THIS EMAIL EXISTS"))
            // this.toasterService.error(err.error.message, err.error.title, { positionClass: 'toast-bottom-center' });
          } catch (e) {
            console.log("!!! Auth Error: error 5")
            // this.auth.logout("PLEASE LOGIN AGAIN. (e5)")

            //this.toasterService.error('An error occurred', '', { positionClass: 'toast-bottom-center' });
          }
          console.log("!!! Auth Error: error 6")
          // this.auth.logout("PLEASE LOGIN AGAIN. (e6)")
          //log error 
        }
        console.log("Manual logout by user")
        return of(err);
      }));

  }

}