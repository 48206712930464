import { ConsService } from 'src/app/services/cons.service';
import { SurveyQuestion } from 'src/app/model/SurveyQuestion';
import { StorageService } from './storage.service';
import { Org } from '../model/Org';
import { DomSanitizer } from "@angular/platform-browser";
import { Injectable } from '@angular/core';
import * as math from 'mathjs';
import { environment } from './../../environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import { userInfo } from 'os';

declare var gtag; //Google-Analytics , GoogleAnalytics, Google Analytics

@Injectable({
  providedIn: 'root'
})
export class UtilzService {
  private storageService: StorageService;
  private consService: ConsService;

  public EFFECTS = ["PAIN", "NUMBNESS", "SIZE INCREASED", "SIZE DECREASED", "GETS WARM", "GETS COLD", "LOSE FUNCTIONALITY"];
  public EFFECT_LEVELS = ["MILD", "MODERATE", "BORDERLINE", "SEVERE"];
  public FREQUENCIES = ["NEVER", "ONCE A  MONTH", "FEW TIMES A MONTH", "FEW TIMES A WEEK", "FEW TIMES A DAY", "HOURLY", "ALL THE TIME"];

  /////////////////////////////////////////////////////////////////////////////////////////////  
  constructor() {
    this.storageService = new StorageService();
    this.consService = new ConsService();
    if (environment.production) {
      gtag('js', new Date());
      gtag('config', 'G-QV2L0E4W0T');
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  createVDPopup(pin_item_type, item, alertController, notificationService, translate, api) {
    let inputArray = []
    let popup_title = translate.instant("NEW VALUE DRIVER")
    inputArray.push({
      name: 'title',
      type: 'text',
      value: item.title ? (item.title + " " + translate.instant("VALUE DRIVER")) : null,
      placeholder: translate.instant("TITLE")//
    })
    inputArray.push({
      name: 'desc',
      type: 'text',
      placeholder: translate.instant("DESCRIPTION")
    })
    inputArray.push({
      name: 'contribution',
      type: 'number',
      placeholder: translate.instant("CONTRIBUTION") + " (1-" + translate.instant("LOW") + ", 5-" + translate.instant("HIGH)")
    })
    inputArray.push({
      name: 'efficiency',
      type: 'number',
      placeholder: translate.instant("EFFICIENCY") + " (1-" + translate.instant("LOW") + ", 5-" + translate.instant("HIGH)")
    })
    this.createVD(pin_item_type, inputArray, popup_title, item, alertController, notificationService, translate, api);
  }

  createVD(pin_item_type, inputArray, popup_title, item, alertController, notificationService, translate, api) {
    alertController.create({
      cssClass: 'my-custom-class',
      header: popup_title,
      inputs: inputArray,
      buttons: [
        {
          text: translate.instant('CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: translate.instant('CREATE'),
          handler: (data) => {
            switch (pin_item_type) {
              case "forum_post":
                data = { ...data, ...{ pin_forum_post: item.id } }; break;
              case "org":
                data = { ...data, ...{ pin_org: item.id } }; break;
            }
            api.createVD(data).subscribe((res: any) => {
              let toastMsg = translate.instant("VALUE DRIVER WAS CREATED");
              notificationService.presentToast(toastMsg, 6, "success");
            })
          }
        }
      ]
    }).then(alert => alert.present());
  }


  //////////////////////////////////////////////////////////////////////
  open_whatsapp(translate, user, subject, intro) {
    if (user.mobile_phone_num) {
      let msg_txt = this.get_msg_opening(translate, user, subject, intro)
      const url = "https://wa.me/" + user.mobile_phone_num + "?text=" + msg_txt;
      window.open(url, "myWindow", 'width=800,height=600');
      window.close();
    } else {
      console.log('ERROR - no mobile phone for this user');
    }
  }

  open_email(translate, user, subject, intro) {
    let msg_subject = this.get_email_subject(translate, user, subject, intro);
    let msg_txt = this.get_msg_opening(translate, user, subject, intro)
    const url = "mailto:" + user.email + "?&subject=" + msg_subject + "&body=" + msg_txt;
    window.open(url, "myWindow", 'width=800,height=600');
    window.close();
  }

  get_email_subject(translate, user, subject, intro) {
    let msg_txt = "";
    if (subject && intro) {
      msg_txt = msg_txt + " " + intro + " " + subject["title"];

    } else if (subject) {
      msg_txt = msg_txt + " " + translate.instant("LET US TALK ABOUT") + " " + subject["title"];
    }
    return msg_txt;

  }

  get_msg_opening(translate, user, subject, intro) {
    let msg_txt = translate.instant("HI") + " " + user.first_name + ",";

    if (subject && intro) {
      msg_txt = msg_txt + " " + intro + " " + subject["title"];

    } else if (subject) {
      msg_txt = msg_txt + " " + translate.instant("LET US TALK ABOUT") + " " + subject["title"];

    } else if (intro) {
      msg_txt = msg_txt + " " + intro;
    }
    return msg_txt;
  }
  ///////////////////////////////////////////////////////////////////
  getPinVDMeta(item) {
    let pin_vd_enabled = true;
    if (!pin_vd_enabled) {
      return null;
    }
    //TODO take stage from items details
    let vd_stage = math.round(math.random() * 5)
    let vd_title = "demo VD title"
    let vd_desc = "demo VD desc"
    let pinvd_icon = "assets/icon/star" + vd_stage + ".svg"

    let show_desc = false;
    let vd_details = {}
    let params = {};
    // params["pps"] = pps;
    if (vd_stage > 0) {
      vd_details["show_create"] = false;
      vd_details["show_details"] = true;
      vd_details["show_edit_to_create"] = false;
      vd_details["vd_title"] = vd_title;
      vd_details["vd_desc"] = vd_desc;
    } else {
      vd_details["show_create"] = true;
      vd_details["show_details"] = false;
      vd_details["show_edit_to_create"] = true;
      vd_details["vd_title"] = item.title;
      vd_details["vd_desc"] = item.desc;
    }
    return vd_details;
  }


  /////////////////////////////////////////////////////////////////////////////////////////////  
  isLangRTL(lang) {
    const is_rtl = (lang == "ar" || lang == "he") ? "true" : "false"
    return is_rtl;
  }
  ///////////////////////////////////////////////////////////
  getSlotDirection() {
    if (this.isCurrentRTL()) {
      return "start";
    } else {
      return "end";
    }
  }

  ///////////////////////////////////////////////////////////////////
  isCurrentRTL() {
    return this.storageService.getValue("is_rtl") == "true";
  }
  /////////////////////////////////////////////////////////////////////////////////////////////  
  resetStorageInLogout() {
    this.storageService.cleanSessionCounters();
    this.storageService.cleanSessionTokens();
    this.storageService.cleanSessionOrg();
  }
  /////////////////////////////////////////////////////////////////////////////
  resetAppFromLocalStorage() {
    let user_lang = this.storageService.getValue(this.storageService.LANG);
    let is_rtl = this.storageService.getValue(this.storageService.IS_RTL);
    let font_size = this.storageService.getValue(this.storageService.FONT_SIZE);

    this.storageService.cleanSessionCounters();

    if (user_lang == "null" || user_lang == null || !user_lang) {
      user_lang = "en-us"
      is_rtl = "false"
    } else {
      is_rtl = this.isLangRTL(user_lang);
    }
    this.storageService.setValue(this.storageService.LANG, user_lang)
    this.storageService.setValue(this.storageService.IS_RTL, is_rtl)
    this.storageService.setValue(this.storageService.FONT_SIZE, font_size);
    let is_rtl_boolean = (is_rtl.toLowerCase() == "true") ? true : false
    this.setFontSize(font_size);
    this.setTextDirection(is_rtl_boolean)
  }
  
  /////////////////////////////////////////////////////////////////////
  getCurrentOrgTitle(){
    return this.storageService.getOrgTitle()
  }

  /////////////////////////////////////////////////////////////////////
  getCurrentOrgId(){
    return this.storageService.getOrgId()
  }

  /////////////////////////////////////////////////////////////////////

  //create our event emitter to send our data to Google Analytic (//Google-Analytics , GoogleAnalytics, Google Analytics)
  public ga_event_emitter(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    if (environment.production) {
      gtag('send', 'event', {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      });
    }
  }
  public ga_page_event() {
    if (environment.production) {
      gtag('set', 'page', "event.urlAfterRedirects");
    }
  }
  public ga_page_view() {
    if (environment.production) {
      gtag('send', 'pageview');
    }
  }

  public getMainAppLogo(org_name = null) {

    if (org_name) {
      switch (org_name) {
        case ("artolio"): { return "assets/img/app/artolio.png"; break; }
        // case ("sibf"): { return "assets/img/app/sibf_w.png"; break; }
        case ("blank"): { return "assets/img/app/logo.blank.png"; break; }
        default: { return "assets/img/app/logo.blank.png"; break; }
      }
    } else {
      switch (environment.platform_customer) {
        case ("energyos"): { return "assets/img/app/energyos.png"; break; }
        case ("artolio"): { return "assets/img/app/artolio.png"; break; }
        case ("welead"): { return "assets/img/app/logo.blank.png"; break; }
        default: { return "assets/img/app/logo.blank.png"; break; }
      }
    }
  }

  public isWhiteLabel() {
    return environment.white_label;
  }

  ///////////////////////////////////////////////
  getVideoUrl(video_host: string, video_id: string): any {
    if (video_host === "YOUTUBE") {
      return 'https://www.youtube.com/embed/' + video_id;
    } else {
      throw new Error("Unsupported Video Host (" + video_host + ").");
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  getBackgroundImgStyle(sanitizer: DomSanitizer, img: any, direction: string = null, page: string = null, repeat: string = null, cover: string = null) {
    // console.log("getBackgroundImgStyle IN: Img: ",img, " , direction:", direction, " , origin page: ", page, " , repeat: ", repeat)
    if (!img) {
      //img_file = this.storageService.getValue(this.storageService.BACKGROUND_IMG);
      img = "olive3.png";
    }
    if (!direction) { //center/left/right/top/button
      direction = "center";
    }

    if (!repeat) {   //repeat if not fill area
      repeat = "no-repeat";
    }
    if (!cover) {   //Streched over whole area
      cover = "/ cover";
    }

    let bg_img = 'assets/img/app_bg/' + img;
    let style = "--background: url('" + bg_img + "') " + repeat + " " + direction + " " + cover + ";";

    return sanitizer.bypassSecurityTrustStyle(style);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getHeaderStyle(sanitizer: DomSanitizer) {
    let style = this.storageService.getValue(this.storageService.HEADER_STYLE);
    if (!style) {
      style = "--background: #F2F1ED; color: #090908;";
    }

    return sanitizer.bypassSecurityTrustStyle(style);

    // GROUND_FEEL : "--background: #848287; color: #ffffff;",
    // MODERN :      "--background: #000000; color: #ffffff;",
    // DEEP_SEA :    "--background: #00648D; color: #ffffff;",
    // GREEN_TREE:   "--background: #2B7317; color: #ffffff;",
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  toMap(objList: object[]) {
    let map = new Map<string,
      object>();
    for (let i in objList) {
      map.set(objList[i]["id"], objList[i]);
    }
    return map;
  }
  //////////////////////////////////////////////////////////                
  jsonToOrgs(res: any) {
    let items = new Array();

    res.forEach((org, index) => {
      items.push(new Org(org));
    });
    return items;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  errorReporter(error_title: string, error_body: string) {
    // const orgId = this.getOrgId();
    let orgIdLastPart = "";
    // if (orgId) {
    //   // orgIdLastPart = orgId.substring(orgId.length - 8, orgId.length);
    // } else {
    //   orgIdLastPart = "Unrecognized Org";
    // }

    console.log("###########################" +
      "\n### title: " + error_title +
      "\n### details: " + error_body +
      "\n### user_id: " + " TODO user_id" +
      "\n### org_id: " + orgIdLastPart +
      "\n### utc_time: " + this.getTime() +
      "\n##########################");
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  setOrg(org: any) {
    this.storageService.setValue(this.storageService.CURRENT_ORG_ACCOUNT_ID, org.id);
    this.storageService.setValue(this.storageService.IS_CURRENT_ORG_VD_ENABLED, org.vd_enabled);
    this.storageService.setValue(this.storageService.CURRENT_ORG_ACCOUNT_TITLE, org.title);
    this.setAppType(org.app_type);
    // TO VALIDATE:
  }

  setArrayToStorage(arr_name, array) {
    localStorage.setItem(arr_name, JSON.stringify(array)); //store colors
  }

  getArrayFromStorage(arr_name) {
    return JSON.parse(localStorage.getItem(arr_name));
  }

  setAppType(app_type) {
    this.storageService.setValue(this.storageService.CURRENT_ORG_APP_TYPE, app_type);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  setCurrentUser(user: any) {
    this.storageService.setValue(this.storageService.CURRENT_USER_ID, user.id);
    this.storageService.setValue(this.storageService.CURRENT_USER_FIRST_NAME, user.first_name);
  }

  getCurrentUserName() {
    return this.storageService.getValue(this.storageService.CURRENT_USER_FIRST_NAME);
  }

  getCurrentUserId() {
    return this.storageService.getValue(this.storageService.CURRENT_USER_ID);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  isOrgVDEnabled() {
    return (this.storageService.getValue(this.storageService.IS_CURRENT_ORG_VD_ENABLED) == 'true');
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  getOrgTitle() {
    return this.storageService.getValue(this.storageService.CURRENT_ORG_ACCOUNT_TITLE);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  getTime() {
    const now = new Date();
    return now.getUTCFullYear() + "-" + (now.getUTCMonth() + 1) + "-" + now.getUTCDate() + " " + now.getUTCHours() + ":" + now.getUTCMinutes() + ":" + now.getUTCSeconds();
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  objectToArray(in_object: Object): Array<Object> {
    const objectArray = Object.entries(in_object);
    return objectArray;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  isEmail(str: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var is_valid = re.test(String(str).toLowerCase());
    return is_valid;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  isPhone(str: string) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    var is_valid = re.test(String(str).toLowerCase());
    return is_valid;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  isNumber(str: string) {
    const re = /[+-]?([0-9]*[.])?[0-9]+/;
    var is_valid = re.test(String(str).toLowerCase());
    return is_valid;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  addHTTPtoURL(str: string) {
    if (str) {
      if (str.indexOf("https://") == -1 && str.indexOf("http://") == -1) {
        str = "https://" + str;
      }
    }
    return str;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  isURL(str: string) {
    const re = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    var is_valid = re.test(String(str).toLowerCase());
    return is_valid;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////  
  presentAlert(header: string, subHeader: string, message: string, button_title: string) {
    const alert = document.createElement('ion-alert');
    alert.setAttribute("width", "80px")
    alert.cssClass = 'login_alert';
    alert.header = header;
    alert.subHeader = subHeader;
    alert.message = message;
    alert.buttons = [button_title];

    document.body.appendChild(alert);
    return alert.present();
  }

  /////////////////////////////////////////////////////////////////////////////////
  getCurrentAppDomain() {
    // let domain = window.location.hostname;
    const href = window.location.href;
    const url = document.createElement('a');
    url.setAttribute('href', href);
    let hostname = url.hostname;
    let port = url.port;
    if (port != null && port != "" && port != undefined) {
      hostname = hostname + ":" + port;
    }
    return hostname;
    // url.hostname;  //  'example.com'
    // url.port;      //  12345
    // url.search;    //  '?startIndex=1&pageSize=10'
    // url.pathname;  //  '/blog/foo/bar'
    // url.protocol;  //  'http:'
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  getCurrentAppProtocol() {
    const href = window.location.href;
    const url = document.createElement('a');
    url.setAttribute('href', href);
    let protocol = url.protocol;
    protocol = protocol.replace(":", "");
    return protocol;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  isLegalPwdFormat(pwd: string) {

    //without special characters

    // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    //with specital chars:
    // var pwd_format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})/;
    //without special chars
    var pwd_format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,15})/;
    //with special characters
    // var pwd_format =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    let pwd_is_legal = pwd.match(pwd_format);
    return (pwd_is_legal);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  areStringsIndentical(pwd1: string, pwd2: string) {
    let identical = (pwd1.localeCompare(pwd2) == 0);
    return (identical);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////

  getUrlParams(param_name = null) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // if (param_name) {
    return urlParams.get(param_name);
    // } else {
    //   return urlParams;
    // }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  getQuestionTypeRoute(questionType: string) {

    switch (questionType) {
      case this.consService.SURVEY_QUESTION_TYPES.MC_ADD:
        return "mcadd";
      case this.consService.SURVEY_QUESTION_TYPES.MMC_ADD:
        return "mmcadd";
      case this.consService.SURVEY_QUESTION_TYPES.DESC_RANGE:
        return "descrange";
      case this.consService.SURVEY_QUESTION_TYPES.MC_IMG:
        return "mcimg";
      case this.consService.SURVEY_QUESTION_TYPES.MC_ADD_PERSON:
        return "mcaddperson";
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  validateQuestion(question: SurveyQuestion, router) {
    if (question == null) {
      router.navigate(["home"]);
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  validateSession(user, router, authService) {
    if (user == null) {
      this.errorReporter("User is null", "");
      router.navigate(["login"]);
    }
    if (authService.isTokenExpired()) {
      this.errorReporter("Session expired", "");
      router.navigate(["login"]);
    }
  }

  evaluateMath(params, str_expression) {

    let keys = Object.keys(params)
    keys.forEach(key => {
      str_expression = this.replaceSubstring(str_expression, key, params[key].toString())
    })
    return math.evaluate(str_expression)
  }

  replaceSubstring(str, substr, value) {
    str = str.split(substr).join(value);
    //return new string
    return str
  }


  setTextDirection(is_rtl: boolean) {
    let direction = "ltr"
    if (is_rtl) {
      direction = "rtl";
    }
    document.documentElement.style.setProperty('--text-direction', direction);
  }


  setFontSize(user_chosen_font_size: any = null) {

    if (!user_chosen_font_size) {
      user_chosen_font_size = this.storageService.getValue(this.storageService.FONT_SIZE);
    }
    if (user_chosen_font_size != "smallest" && user_chosen_font_size != "small" && user_chosen_font_size != "medium" && user_chosen_font_size != "big" && user_chosen_font_size != "biggest") {
      user_chosen_font_size = "medium"
    }
    this.storageService.setValue(this.storageService.FONT_SIZE, user_chosen_font_size);

    let page_title_font_size = "1.4rem";
    let page_desc_font_size = "1.05rem";
    let menu_font_size = "1.05rem";
    let card_title_font_size = "1.1rem";
    let card_sub_title_font_size = "1.0rem";
    let card_desc_font_size = "0.9rem";

    if (user_chosen_font_size == "big") {
      page_title_font_size = "1.5rem";
      page_desc_font_size = "1.15rem";
      menu_font_size = "1.15rem";
      card_title_font_size = "1.2rem";
      card_sub_title_font_size = "1.1rem";
      card_desc_font_size = "1rem";
    } else if (user_chosen_font_size == "small") {
      page_title_font_size = "1.4rem";
      page_desc_font_size = "0.95rem";
      menu_font_size = "0.95rem";
      card_title_font_size = "1rem";
      card_sub_title_font_size = "0.9rem";
      card_desc_font_size = "0.8rem";
    } else if (user_chosen_font_size == "biggest") {
      page_title_font_size = "1.6rem";
      page_desc_font_size = "1.25rem";
      menu_font_size = "1.25rem";
      card_title_font_size = "1.3rem";
      card_sub_title_font_size = "1.2rem";
      card_desc_font_size = "1.1rem";
    } else if (user_chosen_font_size == "smallest") {
      page_title_font_size = "1.3rem";
      page_desc_font_size = "0.85rem";
      menu_font_size = "0.85rem";
      card_title_font_size = "0.9rem";
      card_sub_title_font_size = "0.8rem";
      card_desc_font_size = "0.7rem";
    }
    document.documentElement.style.setProperty('--page_title_font_size', page_title_font_size);
    document.documentElement.style.setProperty('--page_desc_font_size', page_desc_font_size);
    document.documentElement.style.setProperty('--menu_font_size', menu_font_size);
    document.documentElement.style.setProperty('--card_title_font_size', card_title_font_size);
    document.documentElement.style.setProperty('--card_sub_title_font_size', card_sub_title_font_size);
    document.documentElement.style.setProperty('--card_desc_font_size', card_desc_font_size);
  }

  ////////////////////////////////
  getDateString(in_date = null, date_format = "y_m_d") {
    let date;
    if (in_date) {
      date = new Date(in_date)
    } else {
      date = new Date()
    }
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    let date_str;
    if (date_format == "y_m_d") {
      date_str = [date.getFullYear(), "-", (mm > 9 ? '' : '0') + mm, "-", (dd > 9 ? '' : '0') + dd].join('');
    } else if (date_format == "m_d_y") {
      date_str = [ this.consService.MONTHS[mm-1]," ",dd,", ",date.getFullYear()].join('');
    } 

    return date_str;
  }
  ////////////////////////////////
  getMonthFromDate(date) {
    date = new Date(date)
    return date.getMonth() + 1; // getMonth() is zero-based
  }

  ////////////////////////////////
  getDateFromString(date_str) {
    let date = new Date(date_str);
    return date;
    // var mm = date.getMonth() + 1; // getMonth() is zero-based
    // var dd = date.getDate();
    // return [date.getFullYear(),"-",
    // (mm > 9 ? '' : '0') + mm,"-",
    // (dd > 9 ? '' : '0') + dd
    // ].join('');
  }
  ///////////////////////////////
  get_mock_str2index(str, from, to, code_prefix = null) {
    if (!str) return from;
    let a_ascii = 'a'.charCodeAt(0);

    let char1_ascii = str.toLowerCase().charCodeAt(0);

    let diff_char = char1_ascii - a_ascii;
    let diff_range = to - from;

    let code;
    if (diff_char > 0) {
      code = (diff_char % diff_range) + from;
      // console.log('get_mock_str2index diff_char>0: code=',code, " , diff_range:", diff_range," , diff_char:", diff_char, " , from:", from);

    } else {
      code = diff_char + from;
    }
    if (code_prefix) {
      code = code_prefix + code;
    }
    return code;
  }

//////////////////////////////////////////

getClickableBodyParts(map_type) {
  let map = []
  if (map_type=="FEMALE_FRONT") {
    map = 
      [
        { "title": "HEAD", "ry": 54, "rx": 41.5, "id": "svg_26", "cy": 55, "cx": 200 },
        { "title": "RIGHT SHOULDER", "ry": 39, "rx": 32.5, "id": "svg_7", "cy": 153, "cx": 124 },
        { "title": "LEFT SHOULDER", "ry": 39, "rx": 32.5, "id": "svg_8", "cy": 153, "cx": 265 },
        { "title": "RIGHT WRIST", "ry": 12, "rx": 19, "id": "svg_9", "cy": 350, "cx": 67 },
        { "title": "RIGHT ELBOW", "ry": 16.5, "rx": 25, "id": "svg_10", "cy": 270, "cx": 108 },
        { "title": "LEFT ELBOW", "ry": 15, "rx": 25.5, "id": "svg_11", "cy": 270, "cx": 297 },
        { "title": "LEFT WRIST", "ry": 10.5, "rx": 19.5, "id": "svg_12", "cy": 350, "cx": 325 },
        { "title": "LEFT KNEE", "ry": 20.5, "rx": 25.5, "id": "svg_13", "cy": 538, "cx": 228 },
        { "title": "RIGHT KNEE", "ry": 21, "rx": 26, "id": "svg_14", "cy": 537, "cx": 175 },
        { "title": "RIGHT ANKLE", "ry": 17, "rx": 17.5, "id": "svg_15", "cy": 718, "cx": 183 },
        { "title": "LEFT ANKLE", "ry": 16, "rx": 18, "id": "svg_16", "cy": 718, "cx": 220 },
        { "title": "PELVIS", "ry": 42.5, "rx": 81.5, "id": "svg_17", "cy": 357, "cx": 201 },
        { "title": "RIGHT EYE", "ry": 8.5, "rx": 10.5, "id": "svg_2", "cy": 53, "cx": 185 },
        { "title": "NOSE", "ry": 14.5, "rx": 8.5, "id": "svg_1", "cy": 67, "cx": 201 },
        { "title": "LEFT EYE", "ry": 7.5, "rx": 9.5, "id": "svg_3", "cy": 53, "cx": 217 },
        { "title": "RIGHT EAR", "ry": 16, "rx": 8.5, "id": "svg_4", "cy": 65, "cx": 162 },
        { "title": "LEFT EAR", "ry": 13.5, "rx": 8, "id": "svg_5", "cy": 63, "cx": 240 },
        { "title": "MOUTH", "ry": 6.5, "rx": 21, "id": "svg_6", "cy": 86, "cx": 201 },
        { "title": "NECK", "ry": 18, "rx": 34, "id": "svg_8", "cy": 114, "cx": 202 },
        { "title": "ABDOMEN", "ry": 43, "rx": 71, "id": "svg_11", "cy": 286, "cx": 202 },
        { "title": "LEFT THIGH", "ry": 61, "rx": 34.5, "id": "svg_12", "cy": 455, "cx": 237 },
        { "title": "RIGHT THIGH", "ry": 63, "rx": 37.5, "id": "svg_13", "cy": 455, "cx": 167 },
        { "title": "LEFT LEG", "ry": 62, "rx": 27, "id": "svg_14", "cy": 630, "cx": 230 },
        { "title": "RIGHT LEG", "ry": 62, "rx": 27, "id": "svg_15", "cy": 630, "cx": 175 },
        { "title": "LEFT FOOT", "ry": 24.5, "rx": 20, "id": "svg_16", "cy": 745, "cx": 221 },
        { "title": "RIGHT FOOT", "ry": 25.5, "rx": 20, "id": "svg_17", "cy": 746, "cx": 175 },
        { "title": "LEFT ARM", "ry": 43, "rx": 33, "id": "svg_18", "cy": 209, "cx": 284 },
        { "title": "LEFT FOREARM", "ry": 31, "rx": "26", "id": "svg_19", "cy": "304", "cx": 315},
        { "title": "LEFT HAND", "ry": 36.5, "rx": 27, "id": "svg_20", "cy": 393, "cx": 337 },
        { "title": "RIGHT HAND", "ry": 36.5, "rx": 27, "id": "svg_21", "cy": 393, "cx": 52 },
        { "title": "RIGHT FOREARM", "ry": 31, "rx": 28.5, "id": "svg_22", "cy": 304, "cx": 86 },
        { "title": "RIGHT ARM", "ry": 48, "rx": 28.5, "id": "svg_23", "cy": 213, "cx": 113 },
        { "title": "CHEST", "ry": 63, "rx": 62, "id": "svg_9", "cy": 190, "cx": 200 },
        { "title": "PUBIS", "ry": 11, "rx": 15.5, "id": "svg_24", "cy": 360, "cx": 201 },

        // { "title": "BREST", "ry": "34.5", "rx": "78", "id": "svg_25", "cy": "236.18762", "cx": "369.95062" }

        // { "title": "ASTHENIA", "ry": RY, "rx": RX, "id": "svg_51", "cy": 255, "cx": CX },
        // { "title": "BODY ACHE", "ry": RY, "rx": RX, "id": "svg_52", "cy": 290, "cx": CX },
        // { "title": "BODY ODOR", "ry": RY, "rx": RX, "id": "svg_53", "cy": 325, "cx": CX },
        // { "title": "CHILLS", "ry": RY, "rx": RX, "id": "svg_54", "cy": 360, "cx": CX },
        // { "title": "COLDNESS", "ry": RY, "rx": RX, "id": "svg_55", "cy": 395, "cx": CX },
        // { "title": "FATIGUE", "ry": RY, "rx": RX, "id": "svg_56", "cy": 430, "cx": CX },
        // { "title": "FRAILTY", "ry": RY, "rx": RX, "id": "svg_57", "cy": 465, "cx": CX },
        // { "title": "GANGRENE", "ry": RY, "rx": RX, "id": "svg_58", "cy": 500, "cx": CX },
        // { "title": "HALITOSIS", "ry": RY, "rx": RX, "id": "svg_59", "cy": 535, "cx": CX },
        // { "title": "HOT FLASHES", "ry": RY, "rx": RX, "id": "svg_60", "cy": 565, "cx": CX },
        // { "title": "MUSCLE CRAMPS", "ry": RY, "rx": RX, "id": "svg_61", "cy": 600, "cx": CX },
        // { "title": "NIGHT SWEATS", "ry": RY, "rx": RX, "id": "svg_62", "cy": 635, "cx": CX },
        // { "title": "SHIVERING", "ry": RY, "rx": RX, "id": "svg_63", "cy": 670, "cx": CX }

      ]
  // }
    // map =  [
    //     { "title": "HEAD", "ry": 54, "rx": 41.5, "id": "svg_26", "cy": 81.68762, "cx": 371.45062 },
    //     // { "title": "HEAD", "ry": "54", "rx": "41.5", "id": "svg_26", "cy": "81.68762", "cx": "371.45062" },
    //     { "title": "RIGHT SHOULDER", "ry": 39, "rx": 32.5, "id": "svg_7", "cy": 214.68762, "cx": 284.45062 },
    //     { "title": "LEFT SHOULDER", "ry": 39, "rx": 32.5, "id": "svg_8", "cy": 214.68762, "cx": 445.45062 },
    //     { "title": "LEFT SHOULDER", "ry": 39, "rx": 32.5, "id": "svg_8", "cy": 214.68762, "cx": 445.45062 },
    //     { "title": "RIGHT WRIST", "ry": 12, "rx": 19, "id": "svg_9", "cy": 433.68762, "cx": 226.95062 },
    //     { "title": "RIGHT ELBOW", "ry": 16.5, "rx": 25, "id": "svg_10", "cy": 336.18762, "cx": 257.95062 },
    //     { "title": "LEFT ELBOW", "ry": 15, "rx": 25.5, "id": "svg_11", "cy": 333.68762, "cx": 488.45062 },
    //     { "title": "LEFT WRIST", "ry": 10.5, "rx": 19.5, "id": "svg_12", "cy": 441.18762, "cx": 495.45062 },
    //     { "title": "LEFT KNEE", "ry": 20.5, "rx": 25.5, "id": "svg_13", "cy": 615.18762, "cx": 398.45062 },
    //     { "title": "RIGHT KNEE", "ry": 21, "rx": 26, "id": "svg_14", "cy": 614.68762, "cx": 344.95062 },
    //     { "title": "RIGHT ANKLE", "ry": 17, "rx": 17.5, "id": "svg_15", "cy": 795.68762, "cx": 353.45062 },
    //     { "title": "LEFT ANKLE", "ry": 16, "rx": 18, "id": "svg_16", "cy": 795.68762, "cx": 394.95062 },
    //     { "title": "PELVIS", "ry": 42.5, "rx": 81.5, "id": "svg_17", "cy": 434.18762, "cx": 371.45062 },
    //     { "title": "RIGHT EYE", "ry": 8.5, "rx": 10.5, "id": "svg_2", "cy": 80.18762, "cx": 355.45062 },
    //     { "title": "NOSE", "ry": 14.5, "rx": 8.5, "id": "svg_1", "cy": 94.18762, "cx": 371.45062 },
    //     { "title": "LEFT EYE", "ry": 7.5, "rx": 9.5, "id": "svg_3", "cy": 80.18762, "cx": 387.45062 },
    //     { "title": "RIGHT EAR", "ry": 16, "rx": 8.5, "id": "svg_4", "cy": 92.68762, "cx": 332.45062 },
    //     { "title": "LEFT EAR", "ry": 13.5, "rx": 8, "id": "svg_5", "cy": 90.18762, "cx": 409.95062 },
    //     { "title": "MOUTH", "ry": 6.5, "rx": 21, "id": "svg_6", "cy": 113.18762, "cx": 370.95062 },
    //     { "title": "NECK", "ry": 18, "rx": 34, "id": "svg_8", "cy": 141.68762, "cx": 371.95062 },
    //     { "title": "ABDOMEN", "ry": 43, "rx": 71, "id": "svg_11", "cy": 353.68762, "cx": 371.95062 },
    //     { "title": "LEFT THIGH", "ry": 61, "rx": 34.5, "id": "svg_12", "cy": 530.68762, "cx": 407.45062 },
    //     { "title": "RIGHT THIGH", "ry": 63, "rx": 37.5, "id": "svg_13", "cy": 532.68762, "cx": 337.4506 },
    //     { "title": "LEFT LEG", "ry": 63.5, "rx": 27, "id": "svg_14", "cy": 708.18762, "cx": 398.95062 },
    //     { "title": "RIGHT LEG", "ry": 62, "rx": 27, "id": "svg_15", "cy": 706.68762, "cx": 345.95062 },
    //     { "title": "LEFT FOOT", "ry": 24.5, "rx": 31, "id": "svg_16", "cy": 822.18762, "cx": 411.4506 },
    //     { "title": "RIGHT FOOT", "ry": 25.5, "rx": 35, "id": "svg_17", "cy": 823.18762, "cx": 335.95062 },
    //     { "title": "LEFT ARM", "ry": 43, "rx": 33, "id": "svg_18", "cy": 275.68762, "cx": 473.95062 },
    //     { "title": "LEFT FOREARM", "ry": 41, "rx": "26", "id": "svg_19", "cy": "383.68762", "cx": 495.95062 },
    //     { "title": "LEFT HAND", "ry": 36.5, "rx": 27, "id": "svg_20", "cy": 488.18762, "cx": 492.95062 },
    //     { "title": "RIGHT ARM", "ry": 36.5, "rx": 27, "id": "svg_21", "cy": 479.18762, "cx": 216.95062 },
    //     { "title": "RIGHT FOREARM", "ry": 36.5, "rx": 28.5, "id": "svg_22", "cy": 381.18762, "cx": 246.45062 },
    //     { "title": "RIGHT HAND", "ry": 48, "rx": 28.5, "id": "svg_23", "cy": 280.68762, "cx": 273.45062 },
    //     { "title": "CHEST", "ry": 88.5, "rx": 82, "id": "svg_9", "cy": 236.18763, "cx": 369.95062 },
    //     { "title": "PUBIS", "ry": 11, "rx": 15.5, "id": "svg_24", "cy": 487.68762, "cx": 371.45062 },

        // { "title": "BREST", "ry": "34.5", "rx": "78", "id": "svg_25", "cy": "236.18762", "cx": "369.95062" }

        // { "title": "ASTHENIA", "ry": RY, "rx": RX, "id": "svg_51", "cy": 255, "cx": CX },
        // { "title": "BODY ACHE", "ry": RY, "rx": RX, "id": "svg_52", "cy": 290, "cx": CX },
        // { "title": "BODY ODOR", "ry": RY, "rx": RX, "id": "svg_53", "cy": 325, "cx": CX },
        // { "title": "CHILLS", "ry": RY, "rx": RX, "id": "svg_54", "cy": 360, "cx": CX },
        // { "title": "COLDNESS", "ry": RY, "rx": RX, "id": "svg_55", "cy": 395, "cx": CX },
        // { "title": "FATIGUE", "ry": RY, "rx": RX, "id": "svg_56", "cy": 430, "cx": CX },
        // { "title": "FRAILTY", "ry": RY, "rx": RX, "id": "svg_57", "cy": 465, "cx": CX },
        // { "title": "GANGRENE", "ry": RY, "rx": RX, "id": "svg_58", "cy": 500, "cx": CX },
        // { "title": "HALITOSIS", "ry": RY, "rx": RX, "id": "svg_59", "cy": 535, "cx": CX },
        // { "title": "HOT FLASHES", "ry": RY, "rx": RX, "id": "svg_60", "cy": 565, "cx": CX },
        // { "title": "MUSCLE CRAMPS", "ry": RY, "rx": RX, "id": "svg_61", "cy": 600, "cx": CX },
        // { "title": "NIGHT SWEATS", "ry": RY, "rx": RX, "id": "svg_62", "cy": 635, "cx": CX },
        // { "title": "SHIVERING", "ry": RY, "rx": RX, "id": "svg_63", "cy": 670, "cx": CX }

      // ]
    }
    return map;
  }

/////////////////////////////////////////
  getULA() {
    const ula = "<html>        <body>\
      <br>\
      <div class='page_title_font'>Terms and conditions</div>\
      <p class='page_desc_font'>These terms and conditions (&quot;Agreement&quot;) set forth the general terms and conditions of your use of the\
      &quot;WeLead&quot; web application and/or mobile application and/or system and/or any of its\
      related products and services (collectively, &quot;System&quot;). This Agreement is legally binding between you\
      (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and the System's supplier \
      (&quot;WeLead&quot;, &quot;Operator&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). By accessing and using the System,\
      you acknowledge that you have read, understood, and agree to be bound by the terms of\
      this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity (&quot;Organization&quot;, &quot;your Organization&quot;),\
      you represent that you have the authority to bind such entity to this Agreement, in which case the terms\
      &quot;User&quot;, &quot;you&quot; or &quot;your&quot; shall refer to yourself and to your Organization, and to all related directly or indirectly users and entities of your organization.\
      If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not\
      access and use the System. You acknowledge that this Agreement is a contract between you\
      and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the\
      System.</p>\
      <div class='page_title_font'>Age requirement</div>\
      <p class='page_desc_font'>You must be at least 18 years of age to use the System. By using the System\
      and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age.\
      </p>\
      <div class='page_title_font'>User content</div>\
      <p class='page_desc_font'>We do not own any data, information or material (collectively, &quot;Content&quot;) that you submit in the \
      System in the course of using the System. You shall have sole responsibility for the accuracy, quality,\
      integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all\
      submitted Content. We may, but have no obligation to, monitor and review the Content in the System\
      submitted or created by you. You grant us permission to access, copy, distribute, store,\
      transmit, reformat, display and perform the Content of your user account and/or Organization solely as required for the purpose of\
      providing the Services to you. Without limiting any of those representations or warranties, we have the right,\
      though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable\
      opinion, violates any of our policies or is in any way harmful or objectionable. </p>\
      <div class='page_title_font'>Backups</div>\
      <p class='page_desc_font'>We perform regular backups of the Content and will do our best to ensure completeness and accuracy of these\
      backups. In the event of the hardware failure or data loss we will restore backups to minimize the\
      impact and downtime. However, since the System is currently on testing phase, you agree here to keep a copy of the data you add or upload to the system\
      and with your approval to the Agreement you warrant that in a case of data lose, or any other issue related to System or Operator, you would not perform, \
      directly or indirectly, any kind of legal action that involves or relates to the System and/or to the Operator.</p>\
      \
      <div class='page_title_font'>Links to other resources</div>\
      <p class='page_desc_font'>Although the System may link to other resources (such as websites, mobile applications,\
      etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or\
      affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or\
      evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their\
      resources. We do not assume any responsibility or liability for the actions, products, services, and content of\
      any other third parties. You should carefully review the legal statements and other conditions of use of any\
      resource which you access through a link in the System. Your linking to any other\
      off-site resources is at your own risk.</p>\
      \
      <div class='page_title_font'>Prohibited uses</div>\
      <p class='page_desc_font'>In addition to other terms as set forth in the Agreement, you are prohibited from using the System\
      or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any\
      unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local\
      ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights\
      of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on\
      gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or\
      misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be\
      used in any way that will affect the functionality or operation of the System, third\
      party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i)\
      for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the System,\
      third party products and services, or the Internet. We reserve the right to terminate\
      your use of the System for violating any of the prohibited uses.</p>\
      \
      <div class='page_title_font'>Advising and Consulting using the System</div>\
      <p class='page_desc_font'>In case you use the System to supply or consume any kind of consulting or advising (&quot;Consulting Activities&quot;),\
      in any type of channel and media (including, but not limited to: text, chat, image, video, audio, etc.) you have the full responsibility\
      for these Consulting Actions and their effects, and the Operator will have no direct or indirect responsibility.\
      Furthermore, is you supply such Consulting Activities in any relation to the System, by aproving this agreement you warrant you have a\
      valid insurance that covers all types of actions and/or their direct/indirect effects that are related to your Consulting Activities.\
      \
      <div class='page_title_font'>Our use of cookies and similar technologies</div>\
      <p class='page_desc_font'>The System uses cookies and other similar technologies, such as pixels or local storage, to help provide you with a better, faster, and safer experience.\
      Here are some of the ways that our services, including our various websites, SMS, APIs, email notifications, applications, buttons, widgets, and ads, \
      use these technologies: to log you into System, save your preferences, personalize the content you see, protect against spam and abuse, and show you \
      more relevant content.\
      Below we explain how we use these technologies.</p>\
      \
      <div class='page_title_font'>What are cookies, pixels, and local storage?</div>\
      <p class='page_desc_font'>Cookies are small files that websites place on your computer as you browse the web. Like many websites, our System use cookies to discover\
      how people are using our services and to make them work better.\
      A pixel is a small amount of code on a web page or in an email notification. As many services do, we use pixels to learn whether you’ve\
      interacted with certain web or email content. This helps us measure and improve our services and personalize your experience.\
      Local storage is an industry-standard technology that allows a website or application to store information locally on your computer or mobile device. \
      We use local storage to customize what we show you based on your past interactions with our services.</p>\
      \
      <div class='page_title_font'>Why do our services use these technologies?</div> \
      <p class='page_desc_font'>Our services use these technologies to deliver, measure, and improve our services in various ways. \
      These uses generally fall into one of the following categories:\
      (1) Authentication and security: To log you into the System; To protect your security; To let you to view content with limited distribution;\
      To help us detect and fight spam, abuse, and other activities that violate the System Rules and Community Guidelines;\
      For example, these technologies help authenticate your access to System and prevent unauthorized parties from accessing your account.\
      They also let us show you appropriate content through our services.\
      (2) Preferences: To remember information about your browser and your preferences. For example, cookies help us remember your preferred\
      language or the country that you are in. We can then provide you with content in your preferred language without having to ask you each\
      time you visit us. We can also customize content based on your country, such as showing you what topics are trending near you, \
      or to withhold certain content based on applicable local laws.\
      (3) Analytics and research:\
      To help us improve and understand how people use our services, including System buttons, widgets and any published data.\
      For example, cookies help us test different versions of our services to see which particular features or content users prefer. \
      We might also optimize and improve your experience on System by using cookies to see how you interact with our services, such as\
      when and how often you use them and what links you click on. We may use Analytics web services to assist us with this.\
      We might also use cookies to count the number of users that have seen a particular embedded Tweet or timeline.\
      (4) Personalized content:\
      To customize our services with more relevant content, like tailored trends, stories, ads, and suggestions for people to follow.\
      For example, local storage tells us which parts of your data you have viewed already so that we can show you the appropriate new content.\
      Cookies can help us make smarter and more relevant suggestions about what you might enjoy or create value from.\
      (5) Handling the user the next System actions:\
      To assist user experience and to guide the user along the processes that are supported in System, we log relevant actions of user,\
      in order not to ask the user to perform them again unnecessarily, and to deliver the best user experience we can.\
      (6) Advertising:\
      To help us deliver ads, measure their performance, and make them more relevant to you based on criteria like your activity\
      on System and related systems.\
      For example, we can use cookies and pixels to personalize ads and measure their performance. Using these technologies, \
      we can show you ads and evaluate their effectiveness based on your visits to our ad partners' websites. This helps advertisers provide \
      high-quality ads and content that might be more interesting to you.\
      (7) Personalization across devices:\
      By better understanding how devices are related, we can use information from one device to help personalize the user experience on another device.\
      When you log in to System on a device, we associate that device with your System account. Whether or not you are logged in to System, \
      we may also receive information about your devices when, for example, that information is shared by a partner; you visit our website; \
      you visit third-party websites that integrate our content; or you visit a our advertiser’s website or mobile application.\
      We may use this information, most commonly IP addresses and the time at which the information was received, to infer that certain devices\
      are associated with one another, including the devices on which you log in to System.</p>\
      \
      <div class='page_title_font'>Intellectual property rights</div>\
      <p class='page_desc_font'>&quot;Intellectual Property Rights&quot; means all present and future rights conferred by statute, common law or\
      equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill\
      and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property\
      rights, in each case whether registered or unregistered and including all applications and rights to apply for and\
      be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection\
      and any other results of intellectual activity which subsist or will subsist now or in the future in any part of\
      the world. This Agreement does not transfer to you any intellectual property owned by the Operator or third\
      parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely\
      with the Operator. All trademarks, service marks, graphics and logos used in connection with the System,\
      are trademarks or registered trademarks of the Operator or its licensors. Other\
      trademarks, service marks, graphics and logos used in connection with the System may be\
      the trademarks of other third parties. Your use of the System grants you no right or\
      license to reproduce or otherwise use any of the Operator or third party trademarks.</p>\
      \
      <div class='page_title_font'>Copyrights of published data in System</div>\
      <p class='page_desc_font'>Pay attention: regarding the data you/other enter/entered to the System,\
      a copyrighted work may not be duplicated, disseminated, or appropriated by others without the \
      creator's permission. The public display or performance of copyrighted works is similarly restricted.\
      It's not legal to copy content from anywhere, unless you've obtained the author's permission.\
      You will be the direct responsible for copyrights violation that occured by data you have entered to System.\
      We reserve the right to terminate your use of the System for violating any of copyrights or intelectual property.</p>\
      \
      <div class='page_title_font'>Limitation of liability</div>\
      <p class='page_desc_font'>To the fullest extent permitted by applicable law, in no event will the Operator, its affiliates, directors,\
      officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special,\
      punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales,\
      goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business\
      opportunity) however caused, under any theory of liability, including, without limitation, contract, tort,\
      warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the\
      possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable\
      law, the aggregate liability of the Operator and its affiliates, officers, employees, agents, suppliers and\
      licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid\
      in cash by you to the Operator for the prior one month period prior to the first event or occurrence giving rise\
      to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any\
      losses or fails of its essential purpose.\
      Pay attention to the fact that the current version of the System is for internal testing usage only, and does not comply with any\
      regulation and legislation, including General Data Protection Regulation (GDPR) or any other regulation.</p>\
      \
      <div class='page_title_font'>Severability</div>\
      <p class='page_desc_font'>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only\
      to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary\
      so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any\
      provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent\
      jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall\
      constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or\
      portions thereof shall remain in full force and effect.</p>\
      \
      <div class='page_title_font'>Changes and amendments</div>\
      <p class='page_desc_font'>We reserve the right to modify this Agreement or its terms relating to the System at any\
      time, effective upon posting of an updated version of this Agreement in the System. When we do, we\
      will revise the updated date at the bottom of this page. Continued use of the System\
      after any such changes shall constitute your consent to such changes.</p>\
      <div class='page_title_font'>Acceptance of these terms</div>\
      <p class='page_desc_font'>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and\
      using the System you agree to be bound by this Agreement. If you do not agree to abide by\
      the terms of this Agreement, you are not authorized to access or use the System.</p>\
      \
      <div class='page_title_font'>Contacting us</div>\
      <p class='page_desc_font'>If you would like to contact us to understand more about this Agreement or wish to contact us concerning any\
      matter relating to it, you may send an email to info&#64;w&#101;&#108;&#101;a&#100;.ai.</p>\
      <p class='page_desc_font'>This document was last updated on October 2nd, 2021</p>\
      </div>\
      </body>\
      </html>";

    return ula;
  }


}
