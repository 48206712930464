import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { UtilzService } from 'src/app/services/utilz.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  showLoader = false;
  screen = "small";
  menu_collapse = false;

  constructor(public toastController: ToastController, public alertController: AlertController, public auth: AuthService, public utils:UtilzService
    ) {
    this.setScreen()
  }
  
  presentWarning(err) {
    this.presentToast(err, 6, "warning");
  }

  errorHandler(err) {
    if (err.status == 500) {
      this.presentToast("Internal Server Error", 5, "warning");
    }
    else if (err.status == 666) {
      this.presentToast("You have been logged out", 5, "warning");
      this.auth.logout()
    } else {
      err = err.error
      this.utils.errorReporter("NotificationService errorHandelr", err)
      const keys = Object.keys(err);

      keys.forEach(key => {
        const message = err[key][0];
        this.presentToast(message, 5, "warning");
      })
    }
  }

  presentToast(message: string, duration: number = 6, color: string = "primary") {
    const toast = this.toastController.create({
      message: message,
      color: color,
      duration: duration * 1000
    }).then(res => {
      res.present();
    })
  }
  startLoader() {
    this.showLoader = true
  }
  stopLoader() {
    this.showLoader = false
  }

  setScreen() {
    if (window.innerWidth > 1008) {
      this.screen = "large"
    } else {
      this.screen = "small"
    }
  }
}
