import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MockupService } from './mockup';
import { ConsService } from './cons.service';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { UtilzService } from 'src/app/services/utilz.service';
import { keyframes } from '@angular/animations';
// import { null } from 'mathjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  welcomeSlides: {
    title: string;
    description: string;
    image: string;
  }[];
  opened_cases: any;
  closed_cases: any;

  questionNum = 0;

  urlPrefix = environment.url;
  urlPrefixCore = this.urlPrefix + '/core';
  urlPrefixAuth = this.urlPrefixCore;
  urlPrefixSystemAdminApp = this.urlPrefix + '/system_admin_app';
  urlPrefixMainApp = this.urlPrefix + '/main_app';
  urlPrefixForumApp = this.urlPrefix + '/forum_app';
  urlPrefixFinanceApp = this.urlPrefix + '/finance_app';
  urlPrefixSurveyApp = this.urlPrefix + '/survey_app';
  urlPrefixMeetingApp = this.urlPrefix + '/meeting_app';
  urlPrefixLogApp = this.urlPrefix + '/log_app';
  urlPrefixFeedbackApp = this.urlPrefix + '/feedback_app';
  urlPrefixAcademyApp = this.urlPrefix + '/academy_app';
  urlPrefixMediaApp = this.urlPrefix + '/media_app';
  urlPrefixAnalyzerApp = this.urlPrefix + "/analyzer_app";

  jsonFormatUrlPostfix = 'format=json';
  outputURL = '';
  utils = new UtilzService();

  constructor(private httpClient: HttpClient, private mockupService: MockupService, private consService: ConsService,
    private storageService: StorageService) {
    this.welcomeSlides = mockupService.welcomeSlides;
    this.closed_cases = mockupService.closed_cases;
    this.opened_cases = mockupService.opened_cases;
  }

  ///////////////////////////////////////////////////////////
  uploadFile(file) {
    let httpOptions;
    let data = new FormData()
    data.append("file", file)
    data.append("org", this.getOrgId())
    httpOptions = this.getHttpOptions(true, true);
    const api_url = this.urlPrefixMediaApp + "/upload/"
    return this.httpClient.post(api_url, data, httpOptions);//.toPromise()
  }

  ////////////////////////////////////////////////////////////////////////
  createVD(data) {
    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    data.org = org_id
    // TODO: switch
    const api_url = this.urlPrefixAnalyzerApp + "/value_drivers/?org=" + org_id
    return this.httpClient.post(api_url, data, httpOptions)
  }
  ////////////////////////////////////////////////////////////////////////

  getPortfolioVDs() {
    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    // /api/main_app/orgaccount/0205796f-fbfb-4554-a2cc-f31022f5c87a/get_sub_orgs_value_drivers
    const api_url = this.urlPrefixMainApp + "/orgaccount/" + org_id + "/get_sub_orgs_value_drivers"
    return this.httpClient.get(api_url, httpOptions)
  }
  ////////////////////////////////////////////////////////////////////////
  getVDs() {
    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    const api_url = this.urlPrefixAnalyzerApp + "/value_drivers/?org=" + org_id
    return this.httpClient.get(api_url, httpOptions)
  }
  ////////////////////////////////////////////////////////////////////////
  createItem(data, item_type, api_app: string, pps: null) {
    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    data.org = org_id
    data.pps = pps
    data.show_anonym = false
    const api_url = this.urlPrefix + "/" + api_app + "/" + item_type + "/?org=" + org_id
    return this.httpClient.post(api_url, data, httpOptions)
  }
  /////////////////////////////////////////////////////////////////////////////  
  resetGameplay(uop: string) {
    /* If use POST, like it should be: */
    let httpOptions = this.getHttpOptions(true);
    let data = {
      org: this.getOrgId(),
      uop: uop,
      reset_gameplay: "True",
      reset_game_level: "True"
    };

    const api_url = this.urlPrefixMainApp + "/reset_active_pps_list/?org=" + data.org
    return this.httpClient.post(api_url, data, httpOptions)
  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  getSelectOptions(item_type: string, api_app: string, goal: string = "") {

    let url_prefix = this.urlPrefix + "/" + api_app + "/";
    let httpOptions = this.getHttpOptions();

    if (item_type == 'org_users') {
      const url_with_params = this.urlPrefix + "/main_app/org/" + this.getOrgId() + "/users/"
      return this.httpClient.get(url_with_params, httpOptions);
    }

    let apiUrl = url_prefix + item_type + "/";
    let data = {};
    if (item_type.indexOf("types") == -1) {
      data["org"] = this.getOrgId();
    }
    if (goal != null && goal != "") {
      data["goal"] = goal;
    }

    const url_with_params = this.get_url_with_params_postfix(apiUrl, data);
    return this.httpClient.get(url_with_params, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getThings2(item_type: any, thingsByOrgLevel: string, thingSubType: string, api_app: string) {
    let httpOptions = this.getHttpOptions();
    const apiUrl = this.urlPrefix + "/" + api_app + this.getThingsCategory(item_type) + "?org=" + this.getOrgId();
    return this.httpClient.get(apiUrl, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getUserInvite(id: string) {
    let httpOptions = this.getHttpOptions(false);
    const apiUrl = this.urlPrefixCore + "/user_invite_retrieve/" + id + "/";
    return this.httpClient.get(apiUrl, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getAvatar(id: string) {
    let httpOptions = this.getHttpOptions();
    const apiUrl = this.urlPrefixSystemAdminApp + "/avatar_types/" + id;
    return this.httpClient.get(apiUrl, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getThingTypes() {
    let httpOptions = this.getHttpOptions();
    let types = this.httpClient.get(this.urlPrefixSystemAdminApp + "/thing_types/?org=" + this.getOrgId(), httpOptions);
    return types;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getNextAction(pps = null, show_intro = false) {
    let httpOptions = this.getHttpOptions();
    let url = this.urlPrefixMainApp + "/get_next_action_api/"
    let params = {};
    params["org"] = this.getOrgId();
    params["pps"] = pps;
    params["show_intro"] = show_intro;

    const url_with_params = this.get_url_with_params_postfix(url, params);
    let fields = this.httpClient.get(url_with_params, httpOptions);
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getThingDescFields(thing_type: string) {
    let httpOptions = this.getHttpOptions();
    let fields = this.httpClient.get(this.urlPrefixSystemAdminApp + "/thing_desc_types/" + thing_type + "/", httpOptions);
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getOrgRolesTypes() {
    let httpOptions = this.getHttpOptions();
    let fields = this.httpClient.get(this.urlPrefixSystemAdminApp + "/role_category_types/?org=" + this.getOrgId(), httpOptions);
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getLanguagesTypes() {
    let httpOptions = this.getHttpOptions();
    let fields = this.httpClient.get(this.urlPrefixSystemAdminApp + "/languages/", httpOptions);
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getOrgHierarchy() {
    let fields = this.getThingDescFields("mockdata_org_hierarchy");
    return fields;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getFinanceReports() {
    let fields = this.getThingDescFields("mockdata_finance_reports");
    return fields;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getFeatures(category) {
    switch (category) {
      case "COMMUNITY_FEATURES": return this.getThingDescFields("community_features"); break;
      case "VALUE_CREATION_ECOSYSTEM_FEATURES": return this.getThingDescFields("vc_ecosystem_features"); break;
      case "LEADERBOARD_ITEMS": return this.getThingDescFields("leaderboard_items"); break;

      case "INTRO_FEATURES":

        const app_type = this.getOrgAppType();
        // console.log('APP TYPE: ', app_type);
        return this.getThingDescFields("intro_features" + "_" + app_type); break;
    }
    return null;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////

  getOrgAppType() {
    let app_type = this.storageService.getValue(this.storageService.CURRENT_ORG_APP_TYPE);
    if (!app_type || app_type == null || app_type == "") {
      const org_id = this.getOrgId()
      this.getOrg(org_id).subscribe(async res => {
        let app_type = res["app_type"].code;
        console.log('app_type:', app_type);
        this.utils.setAppType(app_type);
      })
    }
    return app_type;
  }
  //////////////////////////////////////////////////////////////////////////////////////////////
  getOrgId() {
    let org_id = this.storageService.getValue(this.storageService.CURRENT_ORG_ACCOUNT_ID);
    if (!org_id || org_id == null || org_id == "") {
      // console.log('ERROR - No Org ID exists ('+org_id+')');
      //TODO: replace with getCurrentOrg()
      // this.getOrg(org_id).subscribe(async res => {
      //   let app_type = res["app_type"].code;
      //   console.log('app_type:', app_type);
      //   this.utils.setAppType(app_type);
      //   return org_id;
      // })
    } else {
      return org_id;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  getCommunityFeatures() {
    let fields = this.getThingDescFields("community_features");
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getValueCreationFeatures() {
    let fields = this.getThingDescFields("vc_ecosystem_features");
    return fields;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getForums() {
    let httpOptions = this.getHttpOptions();
    let url = this.urlPrefixForumApp + "/forum/?org=" + this.getOrgId()
    let fields = this.httpClient.get(url, httpOptions);
    return fields;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  getCurrentUser() {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/current_user/?org=" + this.getOrgId()
    let post = this.httpClient.get(url, httpOptions);
    return post;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getForumPost(forum_post_id: string, forum_id: string) {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixForumApp + "/forum_post/" + forum_post_id + "/?forum=" + forum_id;
    let post = this.httpClient.get(url, httpOptions);
    return post;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getForumPosts(forum_id: string = null, view_type = null, from_date = null, to_date = null, authors = null) {
    if (!forum_id) {
      let forums = this.getForums();
      let forum = forums[0];
      forum_id = forum.id;
    }

    let httpOptions = this.getHttpOptions();
    let url = this.urlPrefixForumApp + "/forum_post/"

    if (view_type == null) {
      view_type = this.consService.BY_LATEST;
    } else {
      view_type = view_type.trim();
    }

    if (view_type == this.consService.BY_LATEST || view_type == this.consService.BY_ME) {
      url = url + "order_by_latest/"
    } else {
      url = url + "order_by_tags/"
    }

    let data = {};

    data["forum"] = forum_id;
    if (from_date) {
      data["from_date"] = from_date;
    }
    if (to_date) {
      data["to_date"] = to_date;
    }
    if (authors) {
      data["authors"] = authors;
    }
    if (view_type == this.consService.BY_ME) {
      data["by_me"] = true;
    }

    const url_with_params = this.get_url_with_params_postfix(url, data);
    let fields = this.httpClient.get(url_with_params, httpOptions);
    return fields;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  createTask(data) {

    // "title": "v06 {{$randomBs}}",
    // "me_lead": "false",

    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    let url = this.urlPrefixMainApp + "/task/?org=" + org_id;
    data["org"] = org_id;

    // const url_with_params = this.get_url_with_params_postfix(url, data);
    const response = this.httpClient.post(url, data, httpOptions);
    return response;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getTasks(task_id = null, scope: string = "user") {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/task/";
    let data = {};
    data["org"] = this.getOrgId();
    data["scope"] = scope;
    const url_with_params = this.get_url_with_params_postfix(url, data);

    const response = this.httpClient.get(url_with_params, httpOptions);
    return response;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  resetOrgFinance() {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixFinanceApp + "/reset_fiscal_constraints_for_org?org="+this.getOrgId();
    // console.log('Delete finance url1: '+this.urlPrefixFinanceApp);
    // console.log('Delete finance url2: '+this.getOrgId());
    // console.log('Delete finance url3: '+"/reset_fiscal_constraints_for_org/?org="+this.getOrgId());
    console.log('Delete finance url4: '+url);
    const response = this.httpClient.delete(url, httpOptions);
    return response;
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////
  // getCarmelChart(){
  //   const url_with_params = "https://api.weleadai.com/api/finance_app/chart_month_org_finance/?chart_type=total_incomes_expenses&compare_target=ONLY_ME&org=5594d51d-346a-46d1-b87f-b49de4c83dbf"
  //   let httpOptions = this.getHttpOptions(true);
  //   const response = this.httpClient.get(url_with_params, httpOptions);
  //   return response;
  // }
  /////////////////////////////////////////////////////////////////
  getKPIChartData(data) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixFinanceApp + "/chart_month_org_finance/";
    const url_with_params = this.get_url_with_params_postfix(url, data);
    const response = this.httpClient.get(url_with_params, httpOptions);
    return response;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  get_url_with_params_postfix(url, params_data) {
    // console.log('params (1): ', params_data);
    let clean_data = {};

    // let index = 0;
    for (let param in params_data) {
      // console.log('param is: ', param, " : ", params_data[param]);
      if (params_data[param] != null && params_data[param] != "null" && params_data[param] != "") {
        // console.log('true param: ', param);
        // params_data = params_data.filter(obj => obj !== param);
        clean_data[param] = params_data[param];
      }
      // index = index+1;
    }
    // console.log('params (2): ', clean_data);

    const searchParams = new URLSearchParams(clean_data);
    return url + "?" + searchParams;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getSurveyingCategories() {
    let httpOptions = this.getHttpOptions();
    let types = this.httpClient.get(this.urlPrefixSystemAdminApp + "/surveying_categories", httpOptions);
    return types;
    // /api/main_app/organizations/
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getOrgs(my_created_orgs = false) {
    let httpOptions = this.getHttpOptions(true);
    let url = "";
    if (my_created_orgs) {
      url = this.urlPrefixMainApp + "/orgaccount/created_by_me"
    } else {
      url = this.urlPrefixMainApp + "/orgaccount/" + '?' + this.jsonFormatUrlPostfix;
      // url = this.getApiUrl("orgaccount", false);
    }

    let orgs = this.httpClient.get(url, httpOptions);
    return orgs;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getUserRelatedOrgs() {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/orgaccount/" + this.getOrgId() + "/get_user_related_orgs"
    let orgs = this.httpClient.get(url, httpOptions);
    return orgs;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getOrgRelatedOrgs() {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/orgaccount/" + this.getOrgId() + "/get_org_related_orgs"
    let orgs = this.httpClient.get(url, httpOptions);
    return orgs;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getThings1(categoryId: any, thingsByOrgLevel: string, thingSubType: string = null) {
    let httpOptions = this.getHttpOptions();
    let apiUrl = this.getThingsApiUrl(categoryId, thingsByOrgLevel, true, "", thingSubType);
    let body = {
      "org": this.getOrgId()
    }

    let things = this.httpClient.get(apiUrl, httpOptions);
    return things;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getThing(id: any, type: any, api_app: any) {
    // console.log('api.getThing: id=', id);
    // console.log('api.getThing: type=', type);
    // console.log('api.getThing: api_app=', api_app);
    let httpOptions = this.getHttpOptions(true);
    let has_item_id = (id != null && id != "")
    let apiUrl = this.getApiUrl(type, has_item_id, id, true, api_app)
    return this.httpClient.get(apiUrl, httpOptions);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////

  updateTask(id: any, data: any) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMainApp + "/" + "task" + "/" + id + "/?org=" + this.getOrgId();
    return this.httpClient.patch(url, data, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////

  updateVD(id: any, data: any) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixAnalyzerApp + "/" + "value_drivers" + "/" + id + "/?org=" + this.getOrgId();
    return this.httpClient.patch(url, data, httpOptions);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  putThing(id: any, type: any, data: any, api_app: string = "main_app") {
    let httpOptions = this.getHttpOptions();
    let url_prefix = this.getURLPrefix(api_app);
    // data["org"] = ;  

    // console.log('org is');
    // if (type=="task") {
    //   console.log('type is task');
    //   type = "tasks"
    // }
    const url = url_prefix + "/" + type + "/" + id + "/?org=" + this.getOrgId();
    // console.log('url = ', url);
    // console.log("PRINT 4:", data)

    return this.httpClient.put(url, data, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  patchThing(id: any, type: any, data: any, api_app: string = "main_app") {
    let httpOptions = this.getHttpOptions();
    let url_prefix = this.getURLPrefix(api_app);
    const url = url_prefix + "/" + type + "/" + id + "/?org=" + this.getOrgId();
    return this.httpClient.patch(url, data, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getTypes(type, api_app, things_level, select_items_source) {

    let httpOptions = this.getHttpOptions(true);
    return this.httpClient.get(this.urlPrefix + '/' + api_app + "/" + type + this.getItemLevelPostFix(things_level, select_items_source), httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getItemLevelPostFix(thingsByOrgLevel: string, select_items_source: string) {
    if (select_items_source == "user") {
      return "/?org=" + this.getOrgId();
    } else if (select_items_source == "system") {
      return "/";
    } else {
      this.utils.errorReporter("select API error", "No item sources mentioned");
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  getApiApp(category) {
    switch (category) {
      case "forum_post": return "forum_app"; break;
      case "value_drivers": return "analyzer_app"; break;
      case "task": return "main_app"; break;
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  deleteThing(category: string, thingId: string, api_app: string = null, forum_id: string = null, scope_is_org = false) {
    console.log('category=', category);
    if (category == "vd") {
      console.log('category==vd');

      category = "value_drivers";
    }
    console.log('category=', category);
    if (!api_app) {
      api_app = this.getApiApp(category);
    }
    console.log('api_app=', api_app);

    let httpOptions = this.getHttpOptions(true);
    let url = this.getApiUrl(category, true, thingId, true, api_app)

    // console.log('*** url = ', url);
    if (category == "forum_post") {
      // console.log('**** is forum post');
      if (forum_id) {
        url = url + "&forum=" + forum_id;
      }
    }
    if (scope_is_org) {
      url = url + "&scope=org";
    }

    return this.httpClient.delete(url, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  sendPostRequest(url: string, body: any, needToken = true) {

    let httpOptions = this.getHttpOptions(needToken);
    return this.httpClient.post(url, body, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  sendPatchRequest(url: string, body: any, needToken = true) {

    let httpOptions = this.getHttpOptions(needToken);
    return this.httpClient.patch(url, body, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getHttpOptions(needToken = true, is_image = false) {
    // in case need for authenticated user
    if (needToken) {
      let token_string = 'Bearer ' + this.storageService.getValue(this.storageService.ACCESS_TOKEN_KEY);

      let httpOptions = {};

      if (is_image) {
        httpOptions = {
          headers: new HttpHeaders({
            'Authorization': token_string
          })
        };

      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token_string
          })
        };
      }
      return httpOptions;
    } else {
      // in case NO need for authenticated user (e.g.: renew password)
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
      return httpOptions;
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getCases(categoryId: any) {
    switch (categoryId) {
      case ('opened_cases'):
        return this.opened_cases;
      case ('closed_cases'):
        return this.closed_cases;
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getWelcomeSlides(app_type): any {

    if (app_type == "fund") {
      return this.getThingDescFields("welcome_intro_fund");
    }
    return this.getThingDescFields("welcome_intro_standard");

  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // TODO replace with real API
  getStructuredProblems(problemId: any) {
    return this.mockupService.structuredProblems;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  sendPwdRenewToEmail(email: string) {
    const needToken = false;
    const domain = this.utils.getCurrentAppDomain();
    const protocol = this.utils.getCurrentAppProtocol();
    const sitename = domain.toUpperCase();

    let body = {
      "email": email,
      "domain": domain,
      "protocol": protocol,
      "site_name": sitename
    }
    let url = this.getSystemApiUrl(this.consService.SEND_RENEW_PWD_TO_EMAIL);

    return (this.sendPostRequest(url, body, needToken));
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  acceptOrgInvitation(user_invite_id, acceptance) {
    let postfix = acceptance ? "accept" : "deny"
    let url = this.urlPrefixCore + "/user_invite_retrieve/" + user_invite_id + "/" + postfix + "/";
    return (this.sendPostRequest(url, null, false));
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  createUserInvite(data) {
    let url = this.urlPrefixCore + "/user_invite/"; //this.getSystemApiUrl(this.consService.SEND_RENEW_PWD_TO_EMAIL);
    return (this.sendPostRequest(url, data, true));
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  createOrgUser(user) {
    user.org_account_id = this.getOrgId();
    user.password = "qwe123QWE!@#";
    let url = this.urlPrefixCore + "/users/"; //this.getSystemApiUrl(this.consService.SEND_RENEW_PWD_TO_EMAIL);
    return (this.sendPostRequest(url, user, true));
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  createUser(data, user_invite_id) {
    let httpOptions = this.getHttpOptions(false);
    let url = this.urlPrefixCore + "/user_invite_retrieve/" + user_invite_id + "/create_user/";
    return this.httpClient.post(url, data, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  updateUser(user_id, data) {
    let url = this.urlPrefixCore + "/users/" + user_id + "/";
    return (this.sendPatchRequest(url, data, true));
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getUser(user_id) {

    // main_app/orgaccount/get_orgs_community_users/<user-id>/?org=0205796f-fbfb-4554-a2cc-f31022f5c87a
    let httpOptions = this.getHttpOptions(true);
    const org = this.getOrgId();
    console.log('get user - this.getOrgId()', org);
    let url = this.urlPrefixCore + "/users/" + user_id + "/?org=" + org;
    return this.httpClient.get(url, httpOptions);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  sendNewPwd(credentials: {
    pwd1: string,
    pwd2: string,
    uid: string,
    token: string
  }) {
    // : { success: boolean, msg: string, res: any} {
    let needToken = false;

    let body = {
      "uid": credentials.uid,
      "token": credentials.token,
      "new_password": credentials.pwd1
    }

    let url = this.getSystemApiUrl(this.consService.SUBMIT_NEW_PWD);
    // url = url+"/"+credentials.uid+"/"+credentials.token+"/";

    return (this.sendPostRequest(url, body, needToken));
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // updateThing() {}
  //TODO From here:
  /*updateProduct(product: Product): Observable<Product> {
    return  this.httpClient.put(this.baseUrl + '/products/' + product.id, product).map(response  => {
      return  new  Product(response);
    }).catch((err)=>{
        this.utils.errorReporter("UpdateThing error", err.toString());
    });
  }*/
  /////////////////////////////////////////////////////////////////////////////////////////////
  getCase(id: any, type: any) {
    let cases_json = [];
    switch (type) {
      case "opened_cases":
        cases_json = this.mockupService.opened_cases;
        break;
      case "closed_cases":
        cases_json = this.mockupService.closed_cases;
        break;
    }

    for (let caseItem of cases_json) {
      if (caseItem.id == id) {
        return caseItem;
      }
    };
  }
  ////////////////////////////////////////////////////////////////////////////////////////////
  getThingsApiUrl(inEntityType: string, thingsByOrgLevel: string, jsonPostfix: boolean, id: string, thingSubType: string) {
    // TODO: send the thingSubType to API as body param, and wait for the filter to be implemented in Server side    
    const org_id = this.getOrgId();
    let thingsLevel = "";
    let thingsCategory = "";

    switch (thingsByOrgLevel) {
      case this.consService.ITEMS_LEVEL.ORG: thingsLevel = "/org/" + this.getOrgId(); break;
      case this.consService.ITEMS_LEVEL.SBU: thingsLevel = "/sbu/"; break;
      case this.consService.ITEMS_LEVEL.CREATOR: thingsLevel = "/user/"; break;
    }

    thingsCategory = this.getThingsCategory(inEntityType);

    // let outputURL = this.urlPrefixMainApp + thingsCategory;
    let apiURL = this.urlPrefixMainApp + "/org/" + org_id + thingsLevel + thingsCategory;
    if (jsonPostfix) {
      apiURL = apiURL + '?' + this.jsonFormatUrlPostfix;
    }
    return apiURL;
  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  getThingsCategory(inEntityType: string) {
    switch (inEntityType) {
      // case this.consService.THING_TYPE.IDEA: return ("/ideas/"); break;
      case this.consService.THING_TYPE.PERSON: return ("/persons/"); break;
      case this.consService.THING_TYPE.DATA_SOURCE: return ("/datasources/"); break;
      case this.consService.THING_TYPE.KIT: return ("/kits/"); break;
      case this.consService.THING_TYPE.LOCATION: return ("/locations/"); break;
      case this.consService.THING_TYPE.PHENOMENA: return ("/phenomenas/"); break;
      // case this.consService.THING_TYPE.PROCESS: return ("/processes/"); break;
      case this.consService.THING_TYPE.SBU: return ("/sbus/"); break;
      case this.consService.THING_TYPE.TANGIBLE: return ("/tangibles/"); break;
      case this.consService.THING_TYPE.STAGE: return ("/stages/"); break;
      // case this.consService.THING_TYPE.COURSE: return ("/courses/"); break;
      // case this.consService.THING_TYPE.LECTURE: return ("/lectures/"); break;
      default: return ("/" + inEntityType + "/"); break;
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getURLPrefix(api_app) {
    switch (api_app) {
      case 'system_admin_app': return this.urlPrefixSystemAdminApp; break;
      case 'main_app': return this.urlPrefixMainApp; break;
      case 'forum_app': return this.urlPrefixForumApp; break;
      case 'finance_app': return this.urlPrefixFinanceApp; break;
      case 'survey_app': return this.urlPrefixSurveyApp; break;
      case 'meeting_app': return this.urlPrefixMeetingApp; break;
      case 'academy_app': return this.urlPrefixAcademyApp; break;
      case 'media_app': return this.urlPrefixMediaApp; break;
      case 'core': return this.urlPrefixCore; break;
      case 'auth': return this.urlPrefixCore; break;
      case 'analyzer_app': return this.urlPrefixAnalyzerApp; break;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  getApiUrl(entity_type: string, have_item_id = false, id = "", jsonPostfix = true, api_app = null): string {
    // console.log('api.getApiUrl : entity_type=', entity_type);
    // console.log('api.getApiUrl : have_item_id=', have_item_id);
    // console.log('api.getApiUrl : api_app=', api_app);
    // console.log('api.getApiUrl : jsonPostfix=', jsonPostfix);
    if (!entity_type) {
      this.utils.errorReporter("API Service: No entity type", "");
      return null;
    }

    if (entity_type == this.consService.LOGIN_ACTION) {
      return this.urlPrefix + "/auth/jwt/create/";
    }

    let url_prefix = api_app ? (this.urlPrefix + '/' + api_app) : this.urlPrefixMainApp;

    if (entity_type == this.consService.ORGS) {
      this.outputURL = url_prefix + "/orgs/";
      return this.outputURL
    }

    this.outputURL = url_prefix + "/" + entity_type + "/";
    if (have_item_id) {
      this.outputURL = this.outputURL + id + "/";
    }
    if (jsonPostfix) {
      this.outputURL = this.outputURL + '?' + this.jsonFormatUrlPostfix + "&org=" + this.getOrgId();
    } else {
      this.outputURL = this.outputURL + '?' + "org=" + this.getOrgId();
    }

    return this.outputURL;
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  getSystemApiUrl(requestType: string): string {
    switch (requestType) {
      case this.consService.SEND_RENEW_PWD_TO_EMAIL:
        return this.urlPrefix + "/auth/users/reset_password/";
      case this.consService.SUBMIT_NEW_PWD:
        return this.urlPrefix + "/auth/users/reset_password_confirm/";
    }
    this.utils.errorReporter("API service - service type doesn't exist.", "api.service");
  }
  /////////////////////////////////////////

  getProcessStates() {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixSystemAdminApp + "/process_state_types/";
    return this.httpClient.get(url, httpOptions)
  }
  /////////////////////////////////////////

  getVDStates() {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixSystemAdminApp + "/vd_status_types/";
    return this.httpClient.get(url, httpOptions)
  }
  /////////////////////////////////////////
  getNextQ(person_process_state_id) {
    let httpOptions = this.getHttpOptions(true);
    //TRUE API - TODO ORR
    // return this.httpClient.get(this.urlPrefixSurveyApp+"/next_task/"+person_process_state_id+"/", httpOptions)

    //MOCKUP API
    const thing_types = ["sbu", "person", "idea", "process", "stage"];
    this.questionNum = this.questionNum % thing_types.length;
    let thing_str = thing_types[this.questionNum];
    this.questionNum = this.questionNum + 1;
    const url = this.urlPrefixSystemAdminApp + "/thing_desc_types/" + thing_str;
    return this.httpClient.get(url, httpOptions)
  }

  /////////////////////////////////////////////////////////
  getMenu(chapter: string = "") {
    let httpOptions = this.getHttpOptions(true);
    let jsonUrl = ""
    const org = this.getOrgId();
    let params = ""
    if (org || chapter) {
      params = "?org=" + org + "&chapter=" + chapter
    }
    jsonUrl = this.urlPrefixSystemAdminApp + "/user_menu/" + params;
    return this.httpClient.get(jsonUrl, httpOptions);
  }
  /////////////////////////////////////////////////////////

  getFinanceTopics(get_topic_categories_for_form: boolean = false, field_for_input_form: boolean = false, parent_topic: string = "", expense_type) {
    let httpOptions = this.getHttpOptions(true);
    let jsonUrl = ""

    let data = {};
    data["org"] = this.getOrgId();
    data["category_for_input_form"] = get_topic_categories_for_form;
    data["field_for_input_form"] = field_for_input_form;
    data["is_income"] = (expense_type=="incomes")?"true":"false";
    if (parent_topic) {
      data["parent_topic"] = parent_topic;
    }
    let url = this.urlPrefixFinanceApp + "/finance_topics/";
    const url_with_params = this.get_url_with_params_postfix(url, data);
    return this.httpClient.get(url_with_params, httpOptions);

    /*
        const org = this.getOrgId();
        let params = ""
        params = "?org=" + org + "&topic_for_input_form=" + get_topic_categories_for_form + "&field_for_input_form=" + field_for_input_form
        if (parent_topic) {
          params = params + "&parent_topic=" + parent_topic
        }
        jsonUrl = this.urlPrefixFinanceApp + "/finance_topics/" + params;
        return this.httpClient.get(jsonUrl, httpOptions);*/
  }

  getCurrencyTypes() {
    let httpOptions = this.getHttpOptions(true);
    let jsonUrl = ""
    const org = this.getOrgId();
    let params = ""
    params = "?org=" + org
    jsonUrl = this.urlPrefixSystemAdminApp + "/currency_types/" + params;
    return this.httpClient.get(jsonUrl, httpOptions);
  }

  getFinancePeriods(limit_to_valid_finance_reporting: boolean = false) {
    let httpOptions = this.getHttpOptions(true);
    let url = ""
    url = this.urlPrefixFinanceApp + "/finance_period_types/"
    if (limit_to_valid_finance_reporting) {
      url = url + "get_valid_reporting_periods/";
    }
    url = url + "?org=" + this.getOrgId();
    return this.httpClient.get(url, httpOptions);
  }


  postFinanceVariableItems(data) {
    let httpOptions = this.getHttpOptions(true);
    let jsonUrl = ""
    const org = this.getOrgId();
    let params = ""
    params = "?org=" + org
    jsonUrl = this.urlPrefixFinanceApp + "/fv_items/";
    return this.httpClient.post(jsonUrl, data, httpOptions);
  }
  
  /////////////////////////////////////////
  getSurveyQuestion() {
    let jsonMocks = [
      "mockdata_question_role",
      "mockdata_question_opportunity",
      "mockdata_question_problem",
      "mockdata_question_boss",
      "mockdata_question_skills"
    ]
    this.questionNum = this.questionNum % jsonMocks.length;
    let mock = jsonMocks[this.questionNum];
    this.questionNum = this.questionNum + 1;
    let fields = this.getThingDescFields(mock);
    return fields;
  }
  ////////////////////////////////////////
  getGeneralList(listType: string) {
    switch (listType) {
      case this.consService.GENERAL_ORG_DEPARTMENTS: return this.getGeneralOrgDepartments();
      case this.consService.GENERAL_DEPARTMENT_ROLES: return this.getGeneralDepartmentRoles();
      default: this.utils.errorReporter("General list does not exist", "");
    }
  }
  ////////////////////////////////////////
  getGameMap(scope) {
    // scope='user';
    // if (scope == "mock") {
    //   let fields = this.getThingDescFields("mockdata_user_game_map");
    //   return fields;
    // }
    // else {
    let httpOptions = this.getHttpOptions(true);
    let url = "";
    let api_prefix = this.urlPrefixMainApp + "/get_game_map/?org=" + this.getOrgId() + "&scope=";
    switch (scope) {
      case 'user':
        url = api_prefix + "user";
        break;
      case 'org':
        url = api_prefix + "org";
        break;
      case 'suborgs':
        url = api_prefix + "suborgs";
        break;
      default:
        url = api_prefix + "user";
        break;
    }
    const game_map = this.httpClient.get(url, httpOptions);
    return game_map;
    // }
  }
  ////////////////////////////////////////
  getCourses(academy = null, course_id = null) {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixAcademyApp + "/course/";

    if (course_id) {
      url = url + course_id + "/";
    }

    let data = {};
    if (academy) {
      data["academy"] = academy;
    }
    const url_with_params = this.get_url_with_params_postfix(url, data);
    const response = this.httpClient.get(url_with_params, httpOptions);
    return response;
  }


  getLectures(course_id, lecture_id = null) {

    if (course_id) {
      let httpOptions = this.getHttpOptions(true);
      let url = this.urlPrefixAcademyApp + "/lecture/";
      let params = {};
      params["course"] = course_id;
      let response;
      if (lecture_id) {
        url = url + lecture_id + "/"
      }
      const url_with_params = this.get_url_with_params_postfix(url, params);
      response = this.httpClient.get(url_with_params, httpOptions);
      return response;

    } else {
      console.log('!!! Error: No course id');
    }
  }

  ////////////////////////////////////////
  getAcademies(academy = null, academy_id = null) {

    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixAcademyApp + "/academy/";

    if (academy_id) {
      url = url + academy_id + "/";
    }

    url = url + "?org=" + this.getOrgId();
    // const url_with_params = this.get_url_with_params_postfix(url, data);
    // const response = this.httpClient.get(url_with_params, httpOptions);
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getConsultants() {

    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/orgaccount/get_consultants/";
    url = url + "?org=" + this.getOrgId();
    // const url_with_params = this.get_url_with_params_postfix(url, data);
    // const response = this.httpClient.get(url_with_params, httpOptions);
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getMoleculesList() {
    let fields = this.getThingDescFields("seerkit_molecules");
    return fields;
  }
  ////////////////////////////////////////
  getProteinsList() {
    let fields = this.getThingDescFields("seerkit_proteins");
    return fields;
  }
  ////////////////////////////////////////
  getAnatomisParts() {
    let fields = this.getThingDescFields("seerkit_anatomic_parts");
    return fields;
  }

  ////////////////////////////////////////
  getVDContributionTimeline() {
    let fields = this.getThingDescFields("mockdata_vd_contribution_timeline");
    return fields;
  }
  ////////////////////////////////////////

  setOpenDateTimeSlots(data) {
    let httpOptions = this.getHttpOptions(true);

    const url = this.urlPrefixMeetingApp + "/open_datetime_slots/";

    data["current_user_time"] = new Date();

    const response = this.httpClient.post(url, data, httpOptions);
    return response;
  }
  ///////////////////////////////////////////////////////
  createDateTimeSlot(date, time_slot) {
    let httpOptions = this.getHttpOptions(true);

    const url = this.urlPrefixMeetingApp + "/datetime_slots/";

    let data = {}
    data["date"] = date;
    data["time_slot"] = time_slot;
    const response = this.httpClient.post(url, data, httpOptions);
    return response;
  }

  ////////////////////////////////////////

  deleteMyOpenDateTimeSlots() {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMeetingApp + "/open_datetime_slots/";
    url = url + "?delete_all=true"
    const response = this.httpClient.delete(url, httpOptions);
    return response;
  }

  getValueCraetionGameplay() {
    let fields = this.getThingDescFields("mockdata_value_gameplay");
    return fields;
  }

  getOrgsForCharts() {
    let fields = this.getThingDescFields("mockdata_orgs_charts");
    return fields;
  }

  getOrgsForArena() {
    let fields = this.getThingDescFields("mockdata_orgs_arena");
    return fields;
  }

  getVDsForCharts() {
    let fields = this.getThingDescFields("mockdata_vds_list");
    return fields;
  }

  getDemoBioCircuit() {
    let fields = this.getThingDescFields("mockdata_bio_circuit");
    return fields;
  }

  getCoreCompetencyForCharts() {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixAnalyzerApp + "/process_pareto_grade/?org=" + this.getOrgId();
    const response = this.httpClient.get(url, httpOptions);
    return response;

    // let fields = this.getThingDescFields("mockdata_core_competency");
    // return fields;
  }

  getCustomerForCharts() {
    // let httpOptions = this.getHttpOptions(true);
    // const url_postfix = 
    //     "/org_pareto_grade/?relation_type_code=SUPPLIER_OF&current_org_role_in_relation=origin&org=";
    // const url = this.urlPrefixAnalyzerApp + url_postfix + this.getOrgId() ;
    // const response = this.httpClient.get(url, httpOptions);
    // return response;

    let fields = this.getThingDescFields("mockdata_customers");
    return fields;
  }

  getProductForCharts() {
    // let httpOptions = this.getHttpOptions(true);
    // const url = this.urlPrefixAnalyzerApp + "/tangible_pareto_grade/?tangible_type_code=PRODUCT&org=" + this.getOrgId() ;
    // const response = this.httpClient.get(url, httpOptions);
    // return response;
    let fields = this.getThingDescFields("mockdata_products");
    return fields;
  }
  /////////////////////////////////////////////////////
  getOrg(org_id) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMainApp + "/orgaccount/" + org_id + "/";
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getUserOpenHours(user = null, date_epoch = null) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMeetingApp + "/open_datetime_slots/";

    let data = {};
    if (user) {
      data["user"] = user;
      data["current_user_time"] = new Date();
    }
    data["date_epoch"] = date_epoch;

    const url_with_params = this.get_url_with_params_postfix(url, data);
    const response = this.httpClient.get(url_with_params, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getSubOrgs() {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMainApp + "/orgaccount/" + this.getOrgId() + "/get_sub_orgs";
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }

  ////////////////////////////////////////
  getMyMeetings(meeting_id = null, date_epoch = null) {
    let httpOptions = this.getHttpOptions(true);
    let url = this.urlPrefixMeetingApp + "/meetings/";

    if (meeting_id) {
      url = url + meeting_id + "/"
    } else if (date_epoch) {
      url = url + "?date=" + date_epoch;
    }
    // console.log('getMyMeetings url: ', url);
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }

  /////////////////////////////////////////////////////
  createUserActionLog(action_code) {

    // console.log('createUserActionLog - action_code=', action_code);
    let httpOptions = this.getHttpOptions(true);

    let data = {
      "org": this.getOrgId(),
      "action_code": action_code
    }

    const url = this.urlPrefixLogApp + "/user_action_log/";
    // console.log('createUserActionLog - url=', url);

    return this.httpClient.post(url, data, httpOptions);
  }
  /////////////////////////////////////////////////////
  createFeedback(data) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixFeedbackApp + "/user_feedback/";

    const response = this.httpClient.post(url, data, httpOptions);
    return response;
  }
  /////////////////////////////////////////////////////
  createMeeting(data) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMeetingApp + "/meetings/";
    data["org"] = this.getOrgId()
    const response = this.httpClient.post(url, data, httpOptions);
    return response;
  }
  /////////////////////////////////////////
  saveMeeting(data) {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMeetingApp + "/meetings/" + data.id + "/";

    const response = this.httpClient.patch(url, data, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getGeneralOpenHours() {
    let httpOptions = this.getHttpOptions(true);
    const url = this.urlPrefixMeetingApp + "/time_slot_types/";
    const response = this.httpClient.get(url, httpOptions);
    return response;
  }
  ////////////////////////////////////////
  getGeneralOrgDepartments() {
    let httpOptions = this.getHttpOptions(true);
    let fields = this.httpClient.get(this.urlPrefixSystemAdminApp + "/department_types/", httpOptions);
    return fields;
  }
  ////////////////////////////////////
  getGeneralDepartmentRoles() {
    let httpOptions = this.getHttpOptions(true);
    let fields = this.httpClient.get(this.urlPrefixSystemAdminApp + "/sbu_role_types/", httpOptions);
    return fields;
  }
  ////////////////////////////////////////
  getFormula(field_id: any) {
    return this.getThingDescFields(field_id);
  }
  ////////////////////////////////////////

  get_orgs_community_users() {
    let httpOptions = this.getHttpOptions(true);
    const org_id = this.getOrgId();
    // /api/main_app/orgaccount/0205796f-fbfb-4554-a2cc-f31022f5c87a/get_sub_orgs_value_drivers
    const api_url = this.urlPrefixMainApp + "/orgaccount/get_orgs_community_users/?org=" + org_id;
    return this.httpClient.get(api_url, httpOptions)
  }
}