import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NotificationService } from './services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilzService } from 'src/app/services/utilz.service';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent {

  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar,
    public notification:NotificationService, private utils: UtilzService, public authService:AuthService,
    private storageService:StorageService, private translateService:TranslateService
  ) {
    this.initializeApp();
  }

  onResize(event){
    if(event.target.innerWidth<990){
      this.notification.screen='small'
    }else{
      this.notification.screen='large'
    }
  }

  initializeApp() {
    this.utils.ga_page_view()

    this.utils.resetAppFromLocalStorage();
    let user_lang = this.storageService.getValue(this.storageService.LANG)
    this.translateService.setDefaultLang(user_lang);

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
