import {  Injectable} from '@angular/core';
import {  ConsService} from './cons.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({  providedIn: 'root'})

export class MockupService {

  token_access = '';

  constructor(private cons: ConsService, public translate:TranslateService) {}

  welcomeSlides = [{
      title: "TIME IS SHORT",
      description: "A BUSY DAY AGAIN AND NO TIME TO PLAN THE FUTURE OF YOUR BUSINESS",
      image: "assets/img/intro/clock.jpg",
    },
    {
      title: "OPPORTUNITIES ARE SOMEWHERE",
      description: "BUSINESS AND OPERATIONAL OPPORTUNITIES DO EXIST BUT YOU DO NOT KNOW WHERE",
      image: "assets/img/intro/search.jpg",
    },
    {
      title: "YOU HAVE A FRIEND",
      description: "WE WILL ASSIST YOU IN FINDING AND IMPLEMENTING THE BEST OPPORTUNITIES",
      image: "assets/img/intro/birds.jpg",
    },    
    {
      title: "FOLLOW THE STEPS",
      description: "SIMPLY FOLLOW THE STEPS IN HOMEPAGE",
      image: "assets/img/intro/cubes.jpg",
    },
    {
      title: "BECOME BUSINESS LEADER",
      description: "YOU ARE ON YOUR WAY TO MASTERING BUSINESS LEADERSHIP",
      image: "assets/img/intro/pencil.jpg",
    }
  ];

  structuredProblems = [{
      id: 1,
      title: "Market demand for variety of products is high."
    },
    {
      id: 2,
      title: "The inventory cost is too high."
    },
    {
      id: 3,
      title: "The sales total is in decline."
    },
  ]

  closed_cases = [{
      id: 1,
      title: "Sales focus solving inventory size.",
      description: "Due to increasing inventory size there is a need for focus.",
      challenge: "Inventory gets bigger and bigger with unfinished goods.",
      solution: "Use Pareto tool to choose the most profitable products.",
      added_value: "$25,000",
      focal_point: "John Davids",
      creation_date: "2020-04-29",
      completion_date: "2020-05-18",
    },
    {
      id: 2,
      title: "Production rate problem solved by TOC.",
      description: "Finished products were not enough. A bottle neck was found and solved by using TOC.",
      challenge: "Bottle neck in soap main factory line.",
      solution: "Theory Of Constraints by Goldrath, using 7 steps to solve bottle neck.",
      added_value: "$53,000",
      focal_point: "Mike Barnes",
      creation_date: "2020-04-24",
      completion_date: "2020-05-12",
    },
    {
      id: 3,
      title: "Sales rate was increased by new insentive policy.",
      description: "Salesperson motivation damage their sales rate.",
      challenge: "Salesperson motivation damages their performances.",
      solution: "Proper value based incentive was defined and implemented.",
      added_value: "$75,000",
      focal_point: "Sara Cardigan.",
      creation_date: "2020-04-27",
      completion_date: "2020-05-11",
    },
    {
      id: 4,
      title: "Customers complaints were solved by improving quality in production chain.",
      description: "Tens of customers complaints in the last 6 months were about quality. An overall quality approach was adopted and compaints stopped.",
      challenge: "Quality issues around the production line and packaging caused many complaints.",
      solution: "Quality monitoring were added to all production stages, and videocam monitoring was set to monitor all stages.",
      added_value: "$260,000",
      focal_point: "Lora McDonski",
      creation_date: "2020-04-22",
      completion_date: "2020-05-11",
    }
  ];

  opened_cases = [{
      id: 1,
      title: "Long Time to market.",
      description: "Time to market (TTL) is 6 weeks, when 2 weeks maximum is required by the market.",
      challenge: "Time to market (TTL) is 6 weeks, when 2 weeks maximum is required by the market.",
      solution: "",
      added_value: "",
      focal_point: "Loren Hills",
      creation_date: "2020-05-27",
      completion_date: "",
    },
    {
      id: 2,
      title: "High Operating Expenses comparing to average industry.",
      description: "After benchmarking with competitors it appears that we have very high Operating Expenses.",
      challenge: "After benchmarking with competitors it appears that we have very high Operating Expenses.",
      solution: "",
      added_value: "",
      focal_point: "",
      creation_date: "2020-05-29",
      completion_date: "",
    }
  ];

  //TODO: move to API
  industries = ["Accounting", "Airlines/Aviation", "Alternative Dispute Resolution", "Alternative Medicine", "Animation", "Apparel/Fashion", 
  "Architecture/Planning", "Arts/Crafts", "Automotive", "Aviation/Aerospace", "Banking/Mortgage", "Biotechnology/Greentech", "Broadcast Media", 
  "Building Materials", "Business Supplies/Equipment", "Capital Markets/Hedge Fund/Private Equity", "Chemicals", "Civic/Social Organization", "Civil Engineering", 
  "Commercial Real Estate", "Computer Games", "Computer Hardware", "Computer Networking", "Computer Software/Engineering", "Computer/Network Security", "Construction", 
  "Consumer Electronics", "Consumer Goods", "Consumer Services", "Cosmetics", "Dairy", "Defense/Space", "Design", "E-Learning", "Education Management", 
  "Electrical/Electronic Manufacturing", "Entertainment/Movie Production", "Environmental Services", "Events Services", "Executive Office", "Facilities Services", 
  "Farming", "Financial Services", "Fine Art", "Fishery", "Food Production", "Food/Beverages", "Fundraising", "Furniture", "Gambling/Casinos", "Glass/Ceramics/Concrete", 
  "Government Administration", "Government Relations", "Graphic Design/Web Design", "Health/Fitness", "Higher Education/Acadamia", "Hospital/Health Care", "Hospitality", 
  "Human Resources/HR", "Import/Export", "Individual/Family Services", "Industrial Automation", "Information Services", "Information Technology/IT", "Insurance", 
  "International Affairs", "International Trade/Development", "Internet", "Investment Banking/Venture", "Investment Management/Hedge Fund/Private Equity", "Judiciary", 
  "Law Enforcement", "Law Practice/Law Firms", "Legal Services", "Legislative Office", "Leisure/Travel", "Library", "Logistics/Procurement", "Luxury Goods/Jewelry", 
  "Machinery", "Management Consulting", "Maritime", "Market Research", "Marketing/Advertising/Sales", "Mechanical or Industrial Engineering", "Media Production", 
  "Medical Equipment", "Medical Practice", "Mental Health Care", "Military Industry", "Mining/Metals", "Motion Pictures/Film", "Museums/Institutions", "Music", 
  "Nanotechnology", "Newspapers/Journalism", "Non-Profit/Volunteering", "Oil/Energy/Solar/Greentech", "Online Publishing", "Other Industry", "Outsourcing/Offshoring", 
  "Package/Freight Delivery", "Packaging/Containers", "Paper/Forest Products", "Performing Arts", "Pharmaceuticals", "Philanthropy", "Photography", "Plastics", 
  "Political Organization", "Primary/Secondary Education", "Printing", "Professional Training", "Program Development", "Public Relations/PR", "Public Safety", 
  "Publishing Industry", "Railroad Manufacture", "Ranching", "Real Estate/Mortgage", "Recreational Facilities/Services", "Religious Institutions", "Renewables/Environment", 
  "Research Industry", "Restaurants", "Retail Industry", "Security/Investigations", "Semiconductors", "Shipbuilding", "Sporting Goods", "Sports", "Staffing/Recruiting", 
  "Supermarkets", "Telecommunications", "Textiles", "Think Tanks", "Tobacco", "Translation/Localization", "Transportation", "Utilities", "Venture Capital/VC", "Veterinary", "Warehousing", "Wholesale", "Wine/Spirits", "Wireless", "Writing/Editing"];
}