import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { ConsService } from 'src/app/services/cons.service';
import { UtilzService } from 'src/app/services/utilz.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { PopoverController } from '@ionic/angular';
import { CommonDropdownComponent } from './../common-dropdown/common-dropdown.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  
  @Input("title") title
  @Input("defaultBackHref") defaultBackHref
  bg_img_style:any;
  header_style:any;
  user_avatar;
  back_arrow_direction = "";
  enable_testing = false;

  constructor( public popoverCtrl: PopoverController,private auth: AuthService, private consService: ConsService, private sanitizer: DomSanitizer, private navigator: NavigatorService, public utils:UtilzService  ){
    this.bg_img_style = this.utils.getBackgroundImgStyle(sanitizer, consService.STYLE_MAIN_MENU_BG_IMG, "left", "shared-header");
    this.header_style = this.utils.getHeaderStyle(sanitizer);  
    this.enable_testing = !(environment.production);
    if (this.utils.isCurrentRTL()){
      this.back_arrow_direction = "'transform: rotate(180deg)'";
      // console.log('header component: is RTL. this.back_arrow_direction = ', this.back_arrow_direction );
    }
    this.user_avatar = "https://i.imgur.com/aJzCBJy.png"
  } 
  /////////////////////////////////////////////////////////////////////////////////////////
    home() {
      this.navigator.go("home");
    }
  /////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit() {
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  ionViewWillEnter() {
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  logout() {
    
    this.auth.logout();
  }

  async openProfileAndSetting(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: CommonDropdownComponent,
      event: ev,
      cssClass: 'calendar-actions',
      translucent: true,
      componentProps: {
        "type": 'profile-and-setting',
      }
    });
    await popover.present();
    const { role } = await popover.onDidDismiss();
  }
}
