import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { NotificationService } from 'src/app/services/notification.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public notificationService: NotificationService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let responded = false
        setTimeout(() => {
            if (!responded) {
                this.notificationService.startLoader()
            }
        }, 200);

        return next.handle(req).pipe(
            finalize(() => {
                responded = true
                this.notificationService.stopLoader()
            })
        );
    }
}

