import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input("rating") rating = 1
  stars=[]
  constructor() { }

  ngOnInit() {
    this.stars = Array(this.rating).fill(this.rating); // [4,4,4,4,4]
  }

}
