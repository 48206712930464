import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UtilzService } from 'src/app/services/utilz.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { StorageService } from 'src/app/services/storage.service';
import { ConsService } from 'src/app/services/cons.service';
import { DomSanitizer } from "@angular/platform-browser";
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  user = null;
  ideas: Observable<any>;
  persons: Observable<any>;
  data_sources: Observable<any>;
  next_question_route: string;
  next_question_json: any;
  elementIsHidden = true;

  surveyNavigationExtras: NavigationExtras;

  bg_img_style: any;
  header_style: any;


  constructor(public auth: AuthService, private navigator: NavigatorService, private router: Router, private httpClient: HttpClient,
    private api: ApiService, private storageService: StorageService, private consService: ConsService, private sanitizer: DomSanitizer,
    public utils: UtilzService, private alertCtrl: AlertController, private translate: TranslateService,
    public notification: NotificationService) {
    this.bg_img_style = this.utils.getBackgroundImgStyle(sanitizer, consService.STYLE_SIDE_MENU_BG_IMG, "left", "shared - side-menu");
    this.header_style = this.utils.getHeaderStyle(sanitizer);


  }
  /////////////////////////////////////////////////////////////////////////////////////////
  goTo(route_to: string) {
    this.navigator.go(route_to, this.surveyNavigationExtras);
    return;
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  menuCollapse() {
    this.notification.menu_collapse = true;
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  menuExpand() {
    this.notification.menu_collapse = false;
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit() {
    //this.auth.setMenu()

  }
  /////////////////////////////////////////////////////////////////////////////////////////
  ionViewWillEnter() {
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  isWhiteLabel() {
    return this.utils.isWhiteLabel();
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  logout() {
    this.alertCtrl.create({
      header: this.translate.instant('NOTICE'),
      message: this.translate.instant('ARE YOU SURE YOU WANT TO LOG OUT?'),
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
      },
      {
        text: this.translate.instant('OK'),
        handler: () => {
          this.auth.logout();
        }
      }]
    }).then(alert => alert.present());
  }

  /////////////////////////////////////////////////////////////////////////////////////////


}


