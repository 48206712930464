import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConsService {

  MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", 
  "September", "October", "November", "December"]

  THING_TYPE = {
    IDEA: "idea", PERSON: "person", DATA_SOURCE: "datasource", PHENOMENA: "phenomena",
    SBU: "sbu", TANGIBLE: "tangible", LOCATION: "location", KIT: "kit", STAGE: "stage", PROCESS: "process",
    PERSON_TO_PERSON_RELATION: "persontoperson", PERSON_TO_SBU_RELATION: "persontosbu", SBU_TO_SBU_RELATION: "sbutosbu",
    COURSE: "course", LECTURE: "lecture"
  }
  THING_TYPES = "THING_TYPES";
  ITEMS_LEVEL = { ORG: "ORG", SBU: "SBU", CREATOR: "CREATOR" }
  ORGS = "orgs";
  DELIMITER = "%^%^";
  SURVEY_QUESTION_TYPES = { MC_ADD: "MC_ADD", MMC_ADD: "MMC_ADD", DESC_RANGE: "DESC_RANGE", MC_IMG: "MC_IMG", MC_ADD_PERSON: "MC_ADD_PERSON" };

  HEADER_COLOR_PALLETE = {
    GROUND_FEEL: "--background: #848287; color: #ffffff;",
    MODERN: "--background: #000000; color: #ffffff;",
    DEEP_SEA: "--background: #00648D; color: #ffffff;",
    GREEN_TREE: "--background: #2B7317; color: #ffffff;",
  }

  STYLE_SIDE_MENU_BG_IMG = 'olive1.png';
  STYLE_MAIN_MENU_BG_IMG = 'olive1.png';
  STYLE_DEFAULT_BG = 'noolive.png';
  STYLE_WALL_BG = 'noolive.png';
  STYLE_CALM_PATTERN_BG_IMG = 'bg03.png';

  // Inside system codes:
  SEND_RENEW_PWD_TO_EMAIL = "SEND_RENEW_PWD_TO_EMAIL";
  SUBMIT_NEW_PWD = "SUBMIT_NEW_PWD";

  GENERAL_ORG_DEPARTMENTS = "general_org_departments";
  GENERAL_DEPARTMENT_ROLES = "general_department_roles";

  LOGIN_ACTION = "login";

  BY_LATEST = "BY_LATEST";
  BY_TAG = "BY_TAG";
  BY_ME = "BY_ME";

  get_mock_chart(type, chart_title, Highcharts, y_axis_label) {
    // stack_bar  bar_pie_res

    let stack_bar =
    {
      chart: {
        type: 'column'
      },
      title: {
        text: chart_title
      },
      xAxis: {
        categories: ['2021', '2022', '2023', '2024', '2025']
      },
      yAxis: {
        min: 0,
        title: {
          text: y_axis_label
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: 'VALUE CREATION : 2021-Q4 CYCLE',
        data: [0, 5, 7, 8, 6],
        color: "#00ba37"
      },
      {
        name: 'VALUE CREATION : 2022-Q1 CYCLE',
        data: [0, 3, 12, 15, 12],
        color: "#9ec2e6"
      },
      {
        name: 'VALUE CREATION : 2022-Q2 CYCLE',
        data: [0, 4, 6, 9, 17],
        color: "#bd8800"
      },
      {
        name: 'BASELINE',
        data: [60, 61, 59, 57, 56],
        color: "#999999"
      }]
    }

    let bar_pie_res =
    {
      "title": {
        "text": chart_title
      },
      "xAxis": {
        "categories": ["January 2022", "February 2022", "March 2022", "April 2022", "May 2022", "June 2022"]
      },
      "yAxis": {
        "title": {
          "text": y_axis_label
        },
      },
      "labels": {
        "items": [{
          "html": "TOTAL PER VALUE DRIVER",
          "style": {
            "left": "50px",
            "top": "18px",
            "color": (
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || "black"
          }
        }]
      },
      "series": [{
        "type": "column",
        "name": "Reducing inventory costs",
        "data": [0, 0, 600, 1000, 1000, 1100]
      }, {
        "type": "column",
        "name": "Solving machinery bottle neck",
        "data": [0, 660, 900, 1000, 900, 700]
      }, {
        "type": "column",
        "name": "Solving SW engineers recruiting bottleneck",
        "data": [0, 0, 0, 1100, 1400, 1200]
      }, {
        "type": "column",
        "name": "Penetrating China market",
        "data": [0, 0, 0, 500, 1600, 1800]
      }, {
        "type": "spline",
        "name": "Average",
        "data": [0, 220, 300, 900, 1300, 1100],
        "marker": {
          "lineWidth": 2,
          "lineColor": Highcharts.getOptions().colors[3],
          "fillColor": "white"
        }
      }, {
        "type": "pie",
        "name": "TOTAL CONTRIBUTION",
        "data": [{
          "name": "CUTTING INVENTORY COSTS WITH PARETO",
          "y": 3700,
          "color": Highcharts.getOptions().colors[0] // Jane's color
        }, {
          "name": "SOLVING PRODUCTION MACHINERY BOTTLENECK",
          "y": 4300,
          "color": Highcharts.getOptions().colors[1] // John's color
        }, {
          "name": "RECRUITING ADDITIONAL BACKEND ENGINEER",
          "y": 4800,
          "color": Highcharts.getOptions().colors[2] // Joe's color
        }],
        "center": [100, 80],
        "size": 100,
        "showInLegend": false,
        "dataLabels": {
          "enabled": false
        }
      }]
    }

    let stack_bar_fund =
    {
      chart: {
        type: 'column'
      },
      title: {
        text: chart_title
      },
      xAxis: {
        categories: ['2021', '2022', '2023', '2024', '2025']
      },
      yAxis: {
        min: 0,
        title: {
          text: y_axis_label
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray'
          }
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },

      series: [{
        name: 'VALUE CREATION : 2021-Q4 CYCLE',
        data: [0, 120, 162, 175, 83],
        color: "#00ba37"
      },
      {
        name: 'VALUE CREATION : 2022-Q1 CYCLE',
        data: [0, 62, 164, 210, 250],
        color: "#9ec2e6"
      },
      {
        name: 'VALUE CREATION : 2022-Q2 CYCLE',
        data: [0, 0, 160, 210, 330],
        color: "#bd8800"
      },
      {
        name: 'BASELINE',
        data: [1240, 1210, 1190, 1145, 1120],
        color: "#999999"
      }]      
    }

    let bar_pie_fund_res =
    {
      "title": {
        "text": chart_title
      },
      "xAxis": {
        "categories": ["January 2022", "February 2022", "March 2022", "April 2022", "May 2022", "June 2022"]
      },
      "yAxis": {
        "title": {
          "text": y_axis_label
        },
      },
      "labels": {
        "items": [{
          "html": "TOTAL PER VALUE DRIVER",
          "style": {
            "left": "50px",
            "top": "18px",
            "color": (
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || "black"
          }
        }]
      },
      "series": [{
        "type": "column",
        "name": "CUTTING INVENTORY COSTS WITH PARETO",
        "data": [0, 0, 1300, 21000, 21000, 22000]
      }, {
        "type": "column",
        "name": "SOLVING PRODUCTION MACHINERY BOTTLENECK",
        "data": [0, 13200, 18000, 18000, 18000, 14000]
      }, {
        "type": "column",
        "name": "RECRUITING ADDITIONAL BACKEND ENGINEER",
        "data": [0, 0, 0, 22000, 28000, 24000]
      }, {
        "type": "spline",
        "name": "Average",
        "data": [0, 4400, 10000, 20000, 22000, 20000],
        "marker": {
          "lineWidth": 2,
          "lineColor": Highcharts.getOptions().colors[3],
          "fillColor": "white"
        }
      }, {
        "type": "pie",
        "name": "TOTAL CONTRIBUTION",
        "data": [{
          "name": "CUTTING INVENTORY COSTS WITH PARETO",
          "y": 3700,
          "color": Highcharts.getOptions().colors[0] // Jane's color
        }, {
          "name": "SOLVING PRODUCTION MACHINERY BOTTLENECK",
          "y": 4300,
          "color": Highcharts.getOptions().colors[1] // John's color
        }, {
          "name": "RECRUITING ADDITIONAL BACKEND ENGINEER",
          "y": 4800,
          "color": Highcharts.getOptions().colors[2] // Joe's color
        }],
        "center": [100, 80],
        "size": 100,
        "showInLegend": false,
        "dataLabels": {
          "enabled": false
        }
      }]
    }

    let bubble_vd =
    {
      chart: {
        type: 'packedbubble',
        height: '100%'
      },
      title: {
        text: chart_title
      },
      tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}:</b> ${point.value}K'
      },
      plotOptions: {
        packedbubble: {
          minSize: '20%',
          maxSize: '100%',
          zMin: 0,
          zMax: 1000,
          layoutAlgorithm: {
            gravitationalConstant: 0.05,
            splitSeries: true,
            seriesInteraction: false,
            dragBetweenSeries: true,
            parentNodeLimit: true
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            filter: {
              property: 'y',
              operator: '>',
              value: 250
            },
            style: {
              color: 'black',
              textOutline: 'none',
              fontWeight: 'normal'
            }
          }
        }
      },
      series: [{
        name: 'OPERATING EXPENSES',
        data: [{
          name: 'Germany',
          value: 767.1
        }, {
          name: 'Croatia',
          value: 20.7
        },
        {
          name: "Belgium",
          value: 97.2
        },
        {
          name: "Czech Republic",
          value: 111.7
        },
        {
          name: "Netherlands",
          value: 158.1
        },
        {
          name: "Spain",
          value: 241.6
        },
        {
          name: "Ukraine",
          value: 249.1
        },
        {
          name: "Poland",
          value: 298.1
        },
        {
          name: "France",
          value: 323.7
        },
        {
          name: "Romania",
          value: 78.3
        },
        {
          name: "United Kingdom",
          value: 415.4
        }, {
          name: "Turkey",
          value: 353.2
        }, {
          name: "Italy",
          value: 337.6
        },
        {
          name: "Greece",
          value: 71.1
        },
        {
          name: "Austria",
          value: 69.8
        },
        {
          name: "Belarus",
          value: 67.7
        },
        {
          name: "Serbia",
          value: 59.3
        },
        {
          name: "Finland",
          value: 54.8
        },
        {
          name: "Bulgaria",
          value: 51.2
        },
        {
          name: "Portugal",
          value: 48.3
        },
        {
          name: "Norway",
          value: 44.4
        },
        {
          name: "Sweden",
          value: 44.3
        },
        {
          name: "Moldova",
          value: 7.8
        },
        {
          name: "Latvia",
          value: 7.5
        },
        {
          name: "Cyprus",
          value: 7.2
        }]
      }, {
        name: 'PRODUCTION',
        data: [{
          name: "Senegal",
          value: 8.2
        },
        {
          name: "Cameroon",
          value: 9.2
        },
               {
          name: "Tunisia",
          value: 24.3
        },
        {
          name: "Angola",
          value: 25
        },
        {
          name: "Libya",
          value: 50.6
        },
        {
          name: "Ivory Coast",
          value: 7.3
        },
        {
          name: "South Africa",
          value: 392.7
        }, {
          name: "Egypt",
          value: 225.1
        }, {
          name: "Algeria",
          value: 141.5
        }]
      }, {
        name: 'RESEARCH AND DEVELOPMENT',
        data: [{
          name: "Australia",
          value: 409.4
        },
        {
          name: "New Zealand",
          value: 34.1
        },
        {
          name: "Papua New Guinea",
          value: 7.1
        }]
      }, {
        name: 'FINANCE',
        data: [{
          name: "Costa Rica",
          value: 7.6
        },
        {
          name: "Honduras",
          value: 8.4
        },
        {
          name: "Jamaica",
          value: 8.3
        },
      {
          name: "Canada",
          value: 566
        }, {
          name: "Mexico",
          value: 456.3
        }]
      }, {
        name: 'INVENTORY',
        data: [{
          name: "El Salvador",
          value: 7.2
        },
        {
          name: "Uruguay",
          value: 8.1
        },
        {
          name: "Bolivia",
          value: 17.8
        },
               {
          name: "Venezuela",
          value: 195.2
        }]
      }, {
        name: 'SALES',
        data: [{
          name: "Nepal",
          value: 6.5
        },
        {
          name: "Georgia",
          value: 6.5
        },
      
        {
          name: "Jordan",
          value: 22.3
        },
        {
          name: "Lebanon",
          value: 21.1
        },
        {
          name: "Azerbaijan",
          value: 31.7
        },
            {
          name: "Philippines",
          value: 96.9
        },
        {
          name: "Vietnam",
          value: 190.2
        },
        {
          name: "United Arab Emirates",
          value: 201.1
        },
        {
          name: "Malaysia",
          value: 227.5
        },
        {
          name: "Kazakhstan",
          value: 236.2
        },
        {
          name: "Thailand",
          value: 272
        },
        {
          name: "Taiwan",
          value: 276.7
        },
        {
          name: "Russia",
          value: 1766.4
        },
        {
          name: "Iran",
          value: 618.2
        },
        {
          name: "Korea",
          value: 610.1
        }]
      }]
    }

    let vd_funnel = {
      chart: {
        type: 'funnel'
      },
      title: {
        text: 'Sales funnel'
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b> ({point.y:,.0f})',
            softConnector: true
          },
          center: ['40%', '50%'],
          neckWidth: '30%',
          neckHeight: '25%',
          width: '80%'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'Unique users',
        data: [
          ['Website visits', 15654],
          ['Downloads', 4064],
          ['Requested price list', 1987],
          ['Invoice sent', 976],
          ['Finalized', 846]
        ]
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  inside: true
                },
                center: ['50%', '50%'],
                width: '100%'
              }
            }
          }
        }]
      }
    }

    let value_creation_iteration_timeline = {
      chart: {
        type: 'timeline'
      },
      accessibility: {
        screenReaderSection: {
          beforeChartFormat: '<h5>{chartTitle}</h5>' +
            '<div>{typeDescription}</div>' +
            '<div>{chartSubtitle}</div>' +
            '<div>{chartLongdesc}</div>' +
            '<div>{viewTableButton}</div>'
        },
        point: {
          valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
        }
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false
      },
      title: {
        text: 'Timeline of Space Exploration'
      },
      subtitle: {
        text: 'Info source: <a href="https://en.wikipedia.org/wiki/Timeline_of_space_exploration">www.wikipedia.org</a>'
      },
      colors: [
        '#4185F3',
        '#427CDD',
        '#406AB2',
        '#3E5A8E',
        '#3B4A68',
        '#363C46'
      ],
      series: [{
        data: [{
          name: 'First dogs',
          label: '1951: First dogs in space',
          description: '22 July 1951 First dogs in space (Dezik and Tsygan) '
        }, {
          name: 'Sputnik 1',
          label: '1957: First artificial satellite',
          description: '4 October 1957 First artificial satellite. First signals from space.'
        }, {
          name: 'First human spaceflight',
          label: '1961: First human spaceflight (Yuri Gagarin)',
          description: 'First human spaceflight (Yuri Gagarin), and the first human-crewed orbital flight'
        }, {
          name: 'First human on the Moon',
          label: '1969: First human on the Moon',
          description: 'First human on the Moon, and first space launch from a celestial body other than the Earth. First sample return from the Moon'
        }, {
          name: 'First space station',
          label: '1971: First space station',
          description: 'Salyut 1 was the first space station of any kind, launched into low Earth orbit by the Soviet Union on April 19, 1971.'
        }, {
          name: 'Apollo–Soyuz Test Project',
          label: '1975: First multinational manned mission',
          description: 'The mission included both joint and separate scientific experiments, and provided useful engineering experience for future joint US–Russian space flights, such as the Shuttle–Mir Program and the International Space Station.'
        }]
      }]
    }

    let cut_diagram = {

      chart: {
        type: 'variwide'
      },

      title: {
        text: 'Labor Costs in Europe, 2016'
      },

      subtitle: {
        text: 'Source: <a href="http://ec.europa.eu/eurostat/web/' +
          'labour-market/labour-costs/main-tables">eurostat</a>'
      },

      xAxis: {
        type: 'category'
      },

      caption: {
        text: 'Column widths are proportional to GDP'
      },

      legend: {
        enabled: false
      },

      series: [{
        name: 'Labor Costs',
        data: [
          ['Norway', 50.2, 335504],
          ['Denmark', 42, 277339],
          ['Belgium', 39.2, 421611],
          ['Sweden', 38, 462057],
          ['France', 35.6, 2228857],
          ['Netherlands', 34.3, 702641],
          ['Finland', 33.2, 215615],
          ['Germany', 33.0, 3144050],
          ['Austria', 32.7, 349344],
          ['Ireland', 30.4, 275567],
          ['Italy', 27.8, 1672438],
          ['United Kingdom', 26.7, 2366911],
          ['Spain', 21.3, 1113851],
          ['Greece', 14.2, 175887],
          ['Portugal', 13.7, 184933],
          ['Czech Republic', 10.2, 176564],
          ['Poland', 8.6, 424269],
          ['Romania', 5.5, 169578]

        ],
        dataLabels: {
          enabled: true,
          format: '€{point.y:.0f}'
        },
        tooltip: {
          pointFormat: 'Labor Costs: <b>€ {point.y}/h</b><br>' +
            'GDP: <b>€ {point.z} million</b><br>'
        },
        colorByPoint: true
      }]
    }

    let gantt = {
      chart: {
        type: 'xrange'
      },
      title: {
        text: chart_title
      },
      accessibility: {
        point: {
          descriptionFormatter: function (point) {
            var ix = point.index + 1,
              category = point.yCategory,
              from = new Date(point.x),
              to = new Date(point.x2);
            return ix + '. ' + category + ', ' + from.toDateString() +
              ' to ' + to.toDateString() + '.';
          }
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: ''
        },
        categories: ['Prototyping', 'Development', 'Testing'],
        reversed: true
      },
      series: [{
        name: 'Project 1',
        // pointPadding: 0,
        // groupPadding: 0,
        borderColor: 'gray',
        pointWidth: 20,
        data: [{
          x: Date.UTC(2014, 10, 21),
          x2: Date.UTC(2014, 11, 2),
          y: 0,
          partialFill: 0.25
        }, {
          x: Date.UTC(2014, 11, 2),
          x2: Date.UTC(2014, 11, 5),
          y: 1
        }, {
          x: Date.UTC(2014, 11, 8),
          x2: Date.UTC(2014, 11, 9),
          y: 2
        }, {
          x: Date.UTC(2014, 11, 9),
          x2: Date.UTC(2014, 11, 19),
          y: 1
        }, {
          x: Date.UTC(2014, 11, 10),
          x2: Date.UTC(2014, 11, 23),
          y: 2
        }],
        dataLabels: {
          enabled: true
        }
      }]

    }

    let hierarchy_chart = {
      chart: {
        height: 600,
        inverted: true
      },

      title: {
        text: 'Highcharts Org Chart'
      },

      accessibility: {
        point: {
          descriptionFormatter: function (point) {
            var nodeName = point.toNode.name,
              nodeId = point.toNode.id,
              nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
              parentDesc = point.fromNode.id;
            return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
          }
        }
      },

      series: [{
        type: 'organization',
        name: 'Highsoft',
        keys: ['from', 'to'],
        data: [
          ['Shareholders', 'Board'],
          ['Board', 'CEO'],
          ['CEO', 'CTO'],
          ['CEO', 'CPO'],
          ['CEO', 'CSO'],
          ['CEO', 'HR'],
          ['CTO', 'Product'],
          ['CTO', 'Web'],
          ['CSO', 'Sales'],
          ['HR', 'Market'],
          ['CSO', 'Market'],
          ['HR', 'Market'],
          ['CTO', 'Market']
        ],
        levels: [{
          level: 0,
          color: 'silver',
          dataLabels: {
            color: 'black'
          },
          height: 25
        }, {
          level: 1,
          color: 'silver',
          dataLabels: {
            color: 'black'
          },
          height: 25
        }, {
          level: 2,
          color: '#980104'
        }, {
          level: 4,
          color: '#359154'
        }],
        nodes: [{
          id: 'Shareholders'
        }, {
          id: 'Board'
        }, {
          id: 'CEO',
          title: 'CEO',
          name: 'Grethe Hjetland',
          image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131126/Highsoft_03862_.jpg'
        }, {
          id: 'HR',
          title: 'HR/CFO',
          name: 'Anne Jorunn Fjærestad',
          color: '#007ad0',
          image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131210/Highsoft_04045_.jpg'
        }, {
          id: 'CTO',
          title: 'CTO',
          name: 'Christer Vasseng',
          image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131120/Highsoft_04074_.jpg'
        }, {
          id: 'CPO',
          title: 'CPO',
          name: 'Torstein Hønsi',
          image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131213/Highsoft_03998_.jpg'
        }, {
          id: 'CSO',
          title: 'CSO',
          name: 'Anita Nesse',
          image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131156/Highsoft_03834_.jpg'
        }, {
          id: 'Product',
          name: 'Product developers'
        }, {
          id: 'Web',
          name: 'Web devs, sys admin'
        }, {
          id: 'Sales',
          name: 'Sales team'
        }, {
          id: 'Market',
          name: 'Marketing team',
          column: 5
        }],
        colorByPoint: false,
        color: '#007ad0',
        dataLabels: {
          color: 'white'
        },
        borderColor: 'white',
        nodeWidth: 65
      }],
      tooltip: {
        outside: true
      },
      exporting: {
        allowHTML: true,
        sourceWidth: 800,
        sourceHeight: 600
      }
    }

    switch (type) {
      case "stack_bar": return stack_bar; break;
      case "bar_pie_res": return bar_pie_res; break;
      case "stack_bar_fund": return stack_bar_fund; break;
      case "bar_pie_fund_res": return bar_pie_fund_res; break;
      case "hierarchy_chart": return hierarchy_chart; break;
      case "gantt": return gantt; break;
      case "cut_diagram": return cut_diagram; break;
      case "value_creation_iteration_timeline": return value_creation_iteration_timeline; break;
      case "vd_funnel": return vd_funnel; break;
      case "bubble_vd": return bubble_vd; break;
      default: console.log('ERROR! cons.service - chart type doesnt exist'); return;
    }
  }

  LAB_TEST = [
    {"code":"Free T4", "title":"Free T4", "incentive":"Low Free T4 is related to low circulating T3 which leads to fatigue.", 
       "result":"15.4", "assumption":"", "is_covered":true, "in_range":"INSIDE", "result_meaning":"NOT RELEVANT TO MY CASE"},
    {"code":"Free T3", "title":"Free T3", "incentive":"Low Free T3 is related to low circulating T3 which leads to fatigue.", 
       "result":"300", "assumption":"", "is_covered":true, "in_range":"INSIDE", "result_meaning":"NOT RELEVANT TO MY CASE"},
    {"code":"TPO", "title":"TPO", "incentive":"High TPO value can indicate that autoimmune may attack the Thyroid gland and lead to disruption of the Thyroid function.",
        "result":"213", "assumption":"", "is_covered":false, "in_range":"BELOW", "result_meaning":"Lack of TPO need to be examine."},
    {"code":"Reverse T3", "title":"Reverse T3", "incentive":"High levels of Reverse T3 is related to abnormal conversion of T4 to T3.", 
       "result":"10", "assumption":"", "is_covered":true, "in_range":"INSIDE", "result_meaning":"NOT RELEVANT TO MY CASE"},
    {"code":"TSH", "title":"TSH", "incentive":"High TSH value is related to low circulating T3 which leads to fatigue.", 
       "result":"50%", "assumption":"", "is_covered":false, "in_range":"ABOVE", "result_meaning":"High TSH is need to be investigated."},
    {"code":"Iodine", "title":"Iodine", "incentive":"Low level of Iodine is related to Iodine defficiency, which leads to fatigue.", 
       "result":"0.3%", "assumption":"", "is_covered":true, "in_range":"INSIDE", "result_meaning":"NOT RELEVANT TO MY CASE"},
  ]
  
}
