import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo:"/home",
    pathMatch:"full"
  },  
  {
    path: 'artolio',
    redirectTo:"/login/artolio",
    pathMatch:"full"
  },
  {
    path: 'login',
    loadChildren: () =>
    import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'cases/ask',
    loadChildren: () => import('./pages/cases/stages/ask/ask.module').then( m => m.AskPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/learn',
    loadChildren: () => import('./pages/cases/stages/learn/learn.module').then( m => m.LearnPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/act',
    loadChildren: () => import('./pages/cases/stages/act/act.module').then( m => m.ActPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/caseslist',
    loadChildren: () => import('./pages/cases/cases-list/cases-list.module').then( m => m.CasesListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/casedetails',
    loadChildren: () => import('./pages/cases/case-details/case-details.module').then( m => m.CaseDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/validation',
    loadChildren: () => import('./pages/cases/stages/challenge-validation/challenge-validation.module').then( m => m.ChallengeValidationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/executionmgmt',
    loadChildren: () => import('./pages/cases/stages/execution-mgmt/execution-mgmt.module').then( m => m.ExecutionMgmtPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'things',
    loadChildren: () => import('./pages/repo/things/things.module').then( m => m.ThingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thing-details',
    loadChildren: () => import('./pages/repo/thing-details/thing-details.module').then( m => m.ThingDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thing-edit',
    loadChildren: () => import('./pages/repo/thing-edit/thing-edit.module').then( m => m.ThingEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'collections',
    loadChildren: () => import('./pages/repo/collections/collections.module').then( m => m.CollectionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'intros/intro',
    loadChildren: () => import('./pages/intros/intro/intro.module').then( m => m.IntroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register/plans',
    loadChildren: () => import('./pages/register/plans/plans.module').then( m => m.PlansPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-pwd-email-req',
    loadChildren: () => import('./pages/user_mgmt/forgot-pwd-email/forgot-pwd-email.module').then( m => m.ForgotPwdEmailPageModule)
  },
  {
    path: 'forgot-pwd-check-email',
    loadChildren: () => import('./pages/user_mgmt/pls-check-email-for-renew/pls-check-email-for-renew.module').then( m => m.PlsCheckEmailForRenewPageModule)
  },
  {
    path: 'forgot-pwd-fill-pwds',
    loadChildren: () => import('./pages/user_mgmt/forgot-pwd-fill-pwds/forgot-pwd-fill-pwds.module').then( m => m.ForgotPwdFillPwdsPageModule)
  },
  {
    path: 'mcadd',
    loadChildren: () => import('./pages/survey/mcadd/mcadd.module').then( m => m.MCAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mmcadd',
    loadChildren: () => import('./pages/survey/mmcadd/mmcadd.module').then( m => m.MMCAddPageModule),
    canActivate: [AuthGuard]    
  },
  {
    path: 'mcimg',
    loadChildren: () => import('./pages/survey/mcimg/mcimg.module').then( m => m.MCImgPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'descrange',
    loadChildren: () => import('./pages/survey/descrange/descrange.module').then( m => m.DescrangePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mcaddperson',
    loadChildren: () => import('./pages/survey/mcaddperson/mcaddperson.module').then( m => m.McaddpersonPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'finance/main',
    loadChildren: () => import('./pages/finance/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'academy/main',
    loadChildren: () => import('./pages/academy/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user/organization',
    loadChildren: () => import('./pages/user_mgmt/orgs-list/orgs-list.module').then( m => m.OrgsListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'full-page-anim',
    loadChildren: () => import('./pages/intros/full-page-anim/full-page-anim.module').then( m => m.FullPageAnimPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/set-open-hours',
    loadChildren: () => import('./pages/meet/set-open-hours/set-open-hours.module').then( m => m.SetOpenHoursPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/main',
    loadChildren: () => import('./pages/meet/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/main',
    loadChildren: () => import('./pages/settings/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/terms',
    loadChildren: () => import('./pages/settings/terms/terms.module').then( m => m.TermsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./pages/settings/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'repo/process-edit',
    loadChildren: () => import('./pages/repo/process-edit/process-edit.module').then( m => m.ProcessEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/chart/kpis',
    loadChildren: () => import('./pages/dashboard/chart/kpis/kpis.module').then( m => m.KpisPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/main',
    loadChildren: () => import('./pages/dashboard/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home-desktop',
    loadChildren: () => import('./pages/home-desktop/home-desktop.module').then( m => m.HomeDesktopPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/mobile/menu.module').then( m => m.MenuPageModule),
    canActivate: [AuthGuard]
  },  
  {
    path: 'menu/role-menu',
    loadChildren: () => import('./pages/menu/role-menu/role-menu.module').then( m => m.RoleMenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orgaccount/userinvite',
    loadChildren: () => import('./pages/user_mgmt/user-invite/user-invite.module').then( m => m.UserInvitePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/chart/org-hierarchy',
    loadChildren: () => import('./pages/dashboard/chart/org-hierarchy/org-hierarchy.module').then( m => m.OrgHierarchyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/chart/dis-joint',
    loadChildren: () => import('./pages/dashboard/chart/dis-joint/dis-joint.module').then( m => m.DisJointPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/chart/crt',
    loadChildren: () => import('./pages/dashboard/chart/crt/crt.module').then( m => m.CrtPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forums',
    loadChildren: () => import('./pages/forums/forum/forums.module').then( m => m.ForumsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forums/forum-post',
    loadChildren: () => import('./pages/forums/forum-post/forum-post.module').then( m => m.ForumPostPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/people',
    loadChildren: () => import('./pages/meet/people/people.module').then( m => m.PeoplePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/schedule',
    loadChildren: () => import('./pages/meet/schedule/schedule.module').then( m => m.SchedulePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/video-meet',
    loadChildren: () => import('./pages/meet/video-meet/video-meet.module').then( m => m.VideoMeetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meet/meet-summary',
    loadChildren: () => import('./pages/meet/meet-summary/meet-summary.module').then( m => m.MeetSummaryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'analysis/opportunities',
    loadChildren: () => import('./pages/analysis/opportunities/opportunities.module').then( m => m.OpportunitiesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'finance/form/:id',
    loadChildren: () => import('./pages/finance/form/form.module').then( m => m.FormPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'task/tasks',
  //   loadChildren: () => import('./pages/task/tasks/tasks.module').then( m => m.TasksPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'meet/meetings',
    loadChildren: () => import('./pages/meet/meetings/meetings.module').then( m => m.MeetingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'academy/courses',
    loadChildren: () => import('./pages/academy/courses/courses.module').then( m => m.CoursesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'academy/course',
    loadChildren: () => import('./pages/academy/course/course.module').then( m => m.CoursePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'academy/lecture',
    loadChildren: () => import('./pages/academy/lecture/lecture.module').then( m => m.LecturePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user/registration',
    loadChildren: () => import('./pages/user_mgmt/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'user/org-invite',
    loadChildren: () => import('./pages/user_mgmt/org-invite/org-invite.module').then( m => m.OrgInvitePageModule)
  },
  {
    path: 'intros/action-intro',
    loadChildren: () => import('./pages/intros/action-intro/action-intro.module').then( m => m.ActionIntroPageModule)
  },
  {
    path: 'strategy/arena',
    loadChildren: () => import('./pages/strategy/arena/arena.module').then( m => m.ArenaPageModule)
  },
  {
    path: 'portfolio/orgs-progress-status',
    loadChildren: () => import('./pages/portfolio/orgs-progress-status/orgs-progress-status.module').then( m => m.OrgsProgressStatusPageModule)
  },
  {
    path: 'analysis/main',
    loadChildren: () => import('./pages/analysis/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'finance/index',
    loadChildren: () => import('./pages/finance/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'finance/chart',
    loadChildren: () => import('./pages/finance/chart/chart.module').then( m => m.ChartPageModule)
  },
  {
    path: 'portfolio/home',
    loadChildren: () => import('./pages/portfolio/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'analysis/valuation',
    loadChildren: () => import('./pages/analysis/valuation/valuation.module').then( m => m.ValuationPageModule)
  },
  {
    path: 'strategy/core-competency',
    loadChildren: () => import('./pages/strategy/core-competency/core-competency.module').then( m => m.CoreCompetencyPageModule)
  },
  {
    path: 'strategy/main',
    loadChildren: () => import('./pages/strategy/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'tools/pareto',
    loadChildren: () => import('./pages/tools/pareto/pareto.module').then( m => m.ParetoPageModule)
  },
  {
    path: 'tools/leaderboard',
    loadChildren: () => import('./pages/tools/leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule)
  },
  {
    path: 'tools/bar-chart-pie',
    loadChildren: () => import('./pages/tools/stacked-bar-chart/stacked-bar-chart.module').then( m => m.StackedBarChartPageModule)
  },
  {
    path: 'portfolio/weekly',
    loadChildren: () => import('./pages/portfolio/weekly/weekly.module').then( m => m.WeeklyPageModule)
  },
  {
    path: 'tools/network-graph',
    loadChildren: () => import('./pages/tools/network-graph/network-graph.module').then( m => m.NetworkGraphPageModule)
  },
  {
    path: 'analysis/valcre-iterations',
    loadChildren: () => import('./pages/analysis/valcre-iterations/valcre-iterations.module').then( m => m.ValcreIterationsPageModule)
  },
  {
    path: 'analysis/vd-clusters',
    loadChildren: () => import('./pages/analysis/vd-clusters/vd-clusters.module').then( m => m.VdClustersPageModule)
  },
  // {
  //   path: 'analysis/vd-list',
  //   loadChildren: () => import('./pages/analysis/vd-list/vd-list.module').then( m => m.VdListPageModule)
  // },
  {
    path: 'tools/timeline',
    loadChildren: () => import('./pages/tools/timeline/timeline.module').then( m => m.TimelinePageModule)
  },
  {
    path: 'tools/parts-topics',
    loadChildren: () => import('./pages/tools/parts-topics/parts-topics.module').then( m => m.PartsTopicsPageModule)
  },
  {
    path: 'tools/gamemap-round',
    loadChildren: () => import('./pages/tools/gamemap-round/gamemap-round.module').then( m => m.GamemapRoundPageModule)
  },
  {
    path: 'tools/galaxy',
    loadChildren: () => import('./pages/tools/galaxy/galaxy.module').then( m => m.GalaxyPageModule)
  },
  {
    path: 'tools/process-cards',
    loadChildren: () => import('./pages/tools/process-cards/process-cards.module').then( m => m.ProcessCardsPageModule)
  },
  {
    path: 'portfolio/fund-flow',
    loadChildren: () => import('./pages/portfolio/fund-flow/fund-flow.module').then( m => m.FundFlowPageModule)
  },
  {
    path: 'tools/funnel',
    loadChildren: () => import('./pages/tools/funnel/funnel.module').then( m => m.FunnelPageModule)
  },
  {
    path: 'tools/list-mng',
    loadChildren: () => import('./pages/tools/list-mng/list-mng.module').then( m => m.ListMngPageModule)
  },
  {
    path: 'tools/synergy',
    loadChildren: () => import('./pages/tools/synergy/synergy.module').then( m => m.SynergyPageModule)
  },
  {
    path: 'tools/gantt',
    loadChildren: () => import('./pages/tools/gantt/gantt.module').then( m => m.GanttPageModule)
  },
  {
    path: 'user_mgmt/user',
    loadChildren: () => import('./pages/user_mgmt/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'community/main',
    loadChildren: () => import('./pages/community/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'medical/body-map',
    loadChildren: () => import('./pages/medical/body-map/body-map.module').then( m => m.BodyMapPageModule)
  },
  {
    path: 'medical/body-problem-spec',
    loadChildren: () => import('./pages/medical/body-problem-spec/body-problem-spec.module').then( m => m.BodyProblemSpecPageModule)
  },
  {
    path: 'medical/pre-diagnose',
    loadChildren: () => import('./pages/medical/pre-diagnose/pre-diagnose.module').then( m => m.PreDiagnosePageModule)
  },
  {
    path: 'medical/lab-order',
    loadChildren: () => import('./pages/medical/lab-order/lab-order.module').then( m => m.LabOrderPageModule)
  },
  {
    path: 'medical/lab-result',
    loadChildren: () => import('./pages/medical/lab-result/lab-result.module').then( m => m.LabResultPageModule)
  },
  {
    path: 'medical/treatment',
    loadChildren: () => import('./pages/medical/treatment/treatment.module').then( m => m.TreatmentPageModule)
  },
  {
    path: 'medical/modeller',
    loadChildren: () => import('./pages/medical/modeller/modeller.module').then( m => m.ModellerPageModule)
  },
  {
    path: 'finance/reports',
    loadChildren: () => import('./pages/finance/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'medical/lead-diagnosis',
    loadChildren: () => import('./pages/medical/lead-diagnosis/lead-diagnosis.module').then( m => m.LeadDiagnosisPageModule)
  },
  {
    path: 'medical/medical-case-initializing',
    loadChildren: () => import('./pages/medical/medical-case-initializing/medical-case-initializing.module').then( m => m.MedicalCaseInitializingPageModule)
  },
  {
    path: 'medical/goals-definition',
    loadChildren: () => import('./pages/medical/goals-definition/goals-definition.module').then( m => m.GoalsDefinitionPageModule)
  },
  {
    path: 'medical/goals-performances',
    loadChildren: () => import('./pages/medical/goals-performances/goals-performances.module').then( m => m.GoalsPerformancesPageModule)
  },
];
 
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
