import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import {  AlertController} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-common-dropdown',
  templateUrl: './common-dropdown.component.html',
  styleUrls: ['./common-dropdown.component.scss'],
})
export class CommonDropdownComponent implements OnInit {

  type:any = ""

  constructor(public auth: AuthService, public router:Router,public popoverCtrl: PopoverController, private alertCtrl: AlertController, private translate: TranslateService) { }

  ngOnInit() {
  }

  changeRoute(path) {
    this.router.navigateByUrl(path)
    this.closeAction()
  }

  closeAction() {
    this.popoverCtrl.dismiss()
  }
  logout() {
    this.alertCtrl.create({
      header: this.translate.instant('NOTICE'),
      message: this.translate.instant('ARE YOU SURE YOU WANT TO LOG OUT?'),
      buttons: [{
        text: this.translate.instant('CANCEL') ,
        role: 'cancel',
      },
      {
        text: this.translate.instant('OK'),
        handler: () => {
          this.closeAction();
          this.auth.logout();
        }
      }]
    }).then(alert => alert.present());
  }
}
