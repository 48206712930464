// export const welead_app_version = "v2022.12.16.1";
export const environment = {
  production: true,
  url : 'https://api.weleadai.com/api',
  
  // platform_customer: '',
  // white_label: false
  
  platform_customer: 'energyos',
  white_label: true,
  server_postfix_hosting: '',  
};       