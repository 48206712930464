import { Component, Input, OnInit } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UtilzService } from 'src/app/services/utilz.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsService } from 'src/app/services/cons.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})

export class ItemCardComponent implements OnInit {
  @Input('item_data') item_data
  @Input('style') style
  @Input('item_default_icon') item_default_icon
  @Input('item_calculate_icon') item_calculate_icon
  @Input('entities_type') entities_type
  @Input('card_size') card_size
  @Input('forum_id') forum_id

  vd_enabled
  slotDirection = "end";
  environment_server_postfix_hosting = environment.server_postfix_hosting;
  currentUserId
  default_org_logo = "assets/img/deafault_org_logo.png"

  constructor(private api: ApiService, private auth: AuthService, private router: Router, private navigator: NavigatorService,
    public utils: UtilzService, private consService: ConsService, private alertController: AlertController,
    public translate: TranslateService, public sanitizer: DomSanitizer, private notificationService: NotificationService) {
    this.vd_enabled = utils.isOrgVDEnabled();
  }

  ngOnInit() { 
    this.currentUserId = this.utils.getCurrentUserId();
  }

  goToOrg(org: any) {
    console.log('goToOrg - org:\n', org);
    this.goTo("portfolio/weekly/" + org.id);
    return;
  }

  goToPerson(person: any){
    console.log('goToPerson - org:\n', person);
    this.goTo("user_mgmt/user/" + person.id);
    return;
  }

  goTo(address: string) {
    this.navigator.go(address);
    return;
  }

  fabOpened(item) {
    // this.sub_orgs.forEach((element, index) => {
    //   if (element.id == item.id) {
    //     setTimeout(() => {
    //       this.sub_orgs[index].is_active = true;
    //     }, 500);
    //   }
    //   this.sub_orgs[index].is_active = false;
    // });
  }

  isOpened(item) {
    if (item) {
      return item.is_active
    }
    return false;
  }

  async openVDPopup(item) {
    if (this.vd_enabled) {
      this.utils.createVDPopup("org", item, this.alertController, this.notificationService, this.translate, this.api);
    }
  }

  open_whatsapp(user, subject, intro) {
    this.utils.open_whatsapp(this.translate, user, subject, intro)
  }
  open_email(user, subject, intro) {
    this.utils.open_email(this.translate, user, subject, intro);
  }

  getOrgIcon(item){
    if(item.uploaded_image_url) {
      return item.uploaded_image_url;
    } else {
      return "assets/img/empty.png";
    }
  }
  getIcon(item) {
    if (this.item_calculate_icon == "core_comp") {
      if (item.efficiency < 3) {
        if (item.contribution < 3) {
          return "assets/img/exit.png";
        }
        return "assets/img/to_improve.png";
      } else {
        if (item.contribution < 3) {
          return "assets/img/question.png";
        }
        return "assets/img/house.png";
      }
    } else {
      return null;
    }
  }

  getRedirectToPath(forum_post_id) {
    return "forums/forum-post/" + forum_post_id + "/" + this.forum_id;
  }

  deleteItem(itemId: any) {
    this.alertController.create({
      header: this.translate.instant('DELETE'),
      message: this.translate.instant('ARE YOU SURE'),
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
      },
      {
        text: this.translate.instant('OK'),
        handler: () => {
          this.api.deleteThing("forum_post", itemId, "forum_app", this.forum_id).subscribe(res => {

            let toastMsg = this.translate.instant("ITEM WAS DELETED");
            this.notificationService.presentToast(toastMsg, 4, "success");
            window.location.reload();
            // this.goTo("forums");
            // this.getData(this.consService.BY_LATEST, null, null, null);

          }), err => {
            console.log(err)
            let toastMsg = this.translate.instant("DELETION FAILED");
            this.notificationService.presentToast(toastMsg, 4, "warning");
          }
        }
      }]
    }).then(alert => alert.present());
  }

  isCurrentUserAuthor(forum_post_author_id) {
    return (this.currentUserId == forum_post_author_id);
  }

}
