import { Injectable } from '@angular/core';
import { ConsService } from 'src/app/services/cons.service';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  consService: ConsService;
  public LANG = "language";
  public IS_RTL = "is_rtl";
  public FONT_SIZE = "font_size";
  public APP_ERROR_MSG = "APP_ERROR_MSG"
  public BACKGROUND_IMG = "BACKGROUND_IMG"
  public HEADER_STYLE = "HEADER_STYLE"
  public ACCESS_TOKEN_KEY = "access_token";
  public REFRESH_TOKEN_KEY = "refresh_token";
  public REFRESH_COUNTER = "refreshCounter";
  public STORAGE_APP_FEATURES = "ftrs";
  public CURRENT_ORG_ACCOUNT_ID = "current_org_id";
  public IS_CURRENT_ORG_VD_ENABLED = "vd_enabled";
  public CURRENT_ORG_ACCOUNT_TITLE = "current_org_title";
  public CURRENT_USER_ID = "user_id";
  public CURRENT_USER_FIRST_NAME = "user_first_name";
  public CURRENT_ORG_APP_TYPE = "current_org_app_type";
  public USER_SYMPTOMS = "user_symptoms";


  constructor() {
    this.consService = new ConsService();
  }

  //////////////////////////////////////////////////////////////
  getValue(item_key: string) {
    return localStorage.getItem(item_key);
  }
  //////////////////////////////////////////////////////////////
  setValue(item_key: string, item_value: string) {
    localStorage.setItem(item_key, item_value);
  }
  //////////////////////////////////////////////////////////////
  setValueArray(item_key: string, item_array: string[]) {
    let value = "";
    for (let item of item_array) {
      value = value + item + this.consService.DELIMITER;
    }
    this.setValue(item_key, value);
  }
  //////////////////////////////////////////////////////////////
  getValueArray(item_key: string) {
    let valuesStr = this.getValue(item_key);
    let valuesArray = valuesStr.split(this.consService.DELIMITER);
    return valuesArray;
  }
  //////////////////////////////////////////////////////////////
  resetValue(item_key: string) {
    this.setValue(item_key, "");
  }
  //////////////////////////////////////////////////////////////
  removeValue(item_key: string) {
    localStorage.removeItem(item_key);
  }
  //////////////////////////////////////////////////////////////
  resetAll() {
    localStorage.clear();
  }
  ///////////////////////////////////////////////////////////////
  getOrgTitle(){
    return this.getValue(this.CURRENT_ORG_ACCOUNT_TITLE);
  }  
  ///////////////////////////////////////////////////////////////
  getOrgId(){
    return this.getValue(this.CURRENT_ORG_ACCOUNT_ID);
  }
  ///////////////////////////////////////////////////////////////
  cleanSessionTokens() {
    this.removeValue(this.ACCESS_TOKEN_KEY);
    this.removeValue(this.REFRESH_TOKEN_KEY);
    this.removeValue(this.CURRENT_USER_ID);
    this.removeValue(this.CURRENT_USER_FIRST_NAME);
    this.removeValue(this.CURRENT_USER_FIRST_NAME);
    this.removeValue(this.CURRENT_USER_FIRST_NAME);
    this.removeValue(this.CURRENT_USER_FIRST_NAME);
    this.removeValue(this.CURRENT_ORG_ACCOUNT_TITLE);
    this.removeValue(this.IS_CURRENT_ORG_VD_ENABLED);
    this.removeValue(this.CURRENT_ORG_ACCOUNT_TITLE);
    this.removeValue(this.CURRENT_ORG_APP_TYPE);
    this.removeValue(this.USER_SYMPTOMS);
  }
  //////////////////////////////////////////////////////////////
  cleanSessionCounters() {
    this.removeValue(this.REFRESH_COUNTER);
    this.removeValue(this.APP_ERROR_MSG);
    this.removeValue(this.STORAGE_APP_FEATURES);    
  }
  //////////////////////////////////////////////////////////////
  cleanSessionOrg() {
    this.removeValue(this.CURRENT_ORG_ACCOUNT_ID);
    this.removeValue(this.IS_CURRENT_ORG_VD_ENABLED);
    this.removeValue(this.CURRENT_ORG_ACCOUNT_TITLE);
  }
}