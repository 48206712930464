import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { PopoverComponent } from './popover/popover.component';
import { RouterModule } from '@angular/router';
import { StarRatingComponent } from './star-rating/star-rating.component';

// swiper 6:
import { SwiperModule } from 'swiper/angular';
// swiper 7:
// import Swiper, { Navigation, Pagination } from 'swiper';

import { CommonDropdownComponent } from './common-dropdown/common-dropdown.component';
import { TagInputModule } from 'ngx-chips';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomIconComponent } from './custom-icon/custom-icon.component';
import { ItemCardComponent } from './item-card/item-card.component';

@NgModule({
  declarations: [
    SideMenuComponent,
    HeaderComponent,
    PopoverComponent,
    StarRatingComponent,
    CommonDropdownComponent,
    CustomIconComponent,
    ItemCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule ,
    RouterModule,
    SwiperModule,
    // Swiper,
    TagInputModule,
    ReactiveFormsModule
  ],
  exports:[
    SideMenuComponent,
    HeaderComponent,
    PopoverComponent,
    RouterModule,
    StarRatingComponent,
    SwiperModule,
    //Swiper,
    TagInputModule,
    ReactiveFormsModule,
    CustomIconComponent,
    ItemCardComponent
  ],
  providers:[
    CommonDropdownComponent
  ],
  entryComponents: [
    CommonDropdownComponent,
  ],
})
export class SharedModule { }
