export class Org {
    public id: string;
    public title: string;
    public address: string;
    public app_type: string;
    public billing_manager: number;
    public vd_enabled = false;
    
    constructor(orgJson : any) {
        this.id = orgJson.id;
        this.title = orgJson.title;
        this.address = orgJson.address;
        this.vd_enabled = orgJson.vd_enabled;
        this.app_type = orgJson.app_type.code;
    }
    
    public toString = () : string => {
        return `Org : id: ${this.id} name: ${this.title} )`;
    }
     
    public getId() {
        return this.id;
    }

    public getName() {
        return this.title;
    }
}
