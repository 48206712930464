import {  Injectable} from '@angular/core';
import {  CanActivate,  ActivatedRouteSnapshot,  RouterStateSnapshot,  UrlTree, Router} from '@angular/router';
import {  Observable} from 'rxjs';
import {  AuthService} from 'src/app/services/auth.service';
import {  AlertController} from '@ionic/angular';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,public router:Router, public translate: TranslateService, private alertCtrl: AlertController, private navigator: NavigatorService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      // this.alertCtrl.create({
      //   header: this.translate.instant('NOTICE'),
      //   message: this.translate.instant('YOUR SESSION HAS EXPIRED. PLEASE LOGIN.'),
      //   buttons: [this.translate.instant('OK')]
      // }).then(alert => alert.present());

      // this.navigator.go('/');
      this.router.navigateByUrl("/login")
      return false;
    }
    else{
      return true;
    }
  }
}
